import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Container,
  IconButton,
  LinearProgress,
  ListItemText,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";

import { channelByIdRequest } from "../../store/channel-by-id/ChannelByIdAction";
import {
  allPlaylistsForChannelRequest,
  playlistEnableRequest,
  deletePlaylist,
  createPlaylist,
  updatePlaylist,
} from "../../store/playlists/PlaylistsAction";

import PaperContentHolder from "../../components/PaperContent";
import DeletePlaylistModal from "./DeletePlaylistModal";
import EditPlaylistModal from "./EditPlaylistModal";

import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import DeleteIcon from "@material-ui/icons/Delete";

import SoundCloudPlaylistDefault from "../../images/soundcloud-logo.jpg";

function ChannelSoundcloudPlaylistsPage() {
  const { id } = useParams();

  const channelById = useSelector((state) => state.channelById);
  const playlists = useSelector((state) => state.playlists);
  const dispatch = useDispatch();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedToDeleteId, setSelectedToDeleteId] = useState();

  const [showCreateUpdateModal, setShowCreateUpdateModal] = useState(false);
  const [selectedToUpdateId, setSelectedToUpdateId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    dispatch(channelByIdRequest(id));
    dispatch(allPlaylistsForChannelRequest(id));
  }, [dispatch, id]);

  if (!channelById.isLoaded || channelById.channelId != id || !playlists.isLoaded || playlists.loadedChannelId != id) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }

  const channel = channelById.channel;

  if (!channel) {
    return <>Invalid channel id is used</>;
  }

  const selectedToDelete = playlists.channelPlaylists.find((playlist) => playlist.id === selectedToDeleteId);
  const selectedToUpdate = playlists.channelPlaylists.find((channel) => channel.id === selectedToUpdateId);

  return (
    <>
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <PaperContentHolder>
          <DeletePlaylistModal
            show={showConfirmDeleteModal}
            onClose={() => setShowConfirmDeleteModal(false)}
            selectedPlaylist={selectedToDelete}
            isDeleting={playlists.isDeleting}
            deleteAction={() => {
              dispatch(deletePlaylist(selectedToDeleteId));
            }}
          />
          <EditPlaylistModal
            onClose={() => setShowCreateUpdateModal(false)}
            show={showCreateUpdateModal}
            selectedPlaylist={selectedToUpdate}
            isUpdating={playlists.isUpdating}
            onSubmit={(values) => {
              setShowCreateUpdateModal(false);

              const payload = {
                channelId: id,
                enabled: false,
                format: "audio",
                ...values,
              };
              if (selectedToUpdateId) {
                payload.enabled = selectedToUpdate.enabled;
                dispatch(updatePlaylist(selectedToUpdateId, payload));
              } else {
                dispatch(createPlaylist(payload));
              }
            }}
          />
          <Typography variant="h4">SoundCloud Account - {channel.title}</Typography>
          <Typography variant="h5">Channel Playlists</Typography>
          <LinearProgress style={{ visibility: playlists.isChangingEnabledValue ? "visible" : "hidden" }} />
          <TableContainer component={Paper} style={{ maxHeight: "725px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow></TableRow>
                <TableRow>
                  <TableCell>Playlist</TableCell>
                  <TableCell align="right" width="120px">
                    Track count
                  </TableCell>
                  <TableCell align="center" width="150px">
                    Show to Agents
                  </TableCell>
                  <TableCell align="center" width="220px">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {playlists.channelPlaylists.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6}>No Playlists</TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
                {playlists.channelPlaylists.map((playlist, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={SoundCloudPlaylistDefault}
                        alt="test"
                        style={{ width: "100px", height: "100px", marginRight: "30px" }}
                      />
                      <ListItemText primary={playlist.title} secondary={playlist.description} />
                    </TableCell>
                    <TableCell align="right">{playlist.numberOfItems}</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        name="showToAgent"
                        color="primary"
                        checked={playlist.enabled}
                        disabled={playlists.isChangingEnabledValue}
                        onChange={(e) => {
                          dispatch(playlistEnableRequest(playlist.id, e.target.checked));
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        style={{
                          marginRight: "5px",
                        }}
                        onClick={(e) => {
                          setShowCreateUpdateModal(true);
                          setSelectedToUpdateId(playlist.id);
                        }}
                      >
                        <EditIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        style={{
                          marginRight: "5px",
                        }}
                        onClick={(e) => {
                          history.push(`/admin/channels/soundcloud/${id}/playlists/${playlist.id}`);
                        }}
                      >
                        <ListIcon fontSize="large" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          setShowConfirmDeleteModal(true);
                          setSelectedToDeleteId(playlist.id);
                        }}
                      >
                        <DeleteIcon fontSize="large" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={6}>
                    <Container style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ width: "195px" }}
                        onClick={() => {
                          setSelectedToUpdateId(null);
                          setShowCreateUpdateModal(true);
                        }}
                      >
                        Add Playlist
                      </Button>
                    </Container>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </PaperContentHolder>
      </Paper>
    </>
  );
}

export default ChannelSoundcloudPlaylistsPage;
