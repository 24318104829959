import axios from "axios";
import { axiosInstance } from "../../network/api";
const handlerEnabled = true;

const apiFetchAllUsers = async () => {
  return await axiosInstance.get(`/profiles`, { handlerEnabled });
};
const apiFetchAllUserForReview = async () => {
  return await axiosInstance.get("/profiles/review",{handlerEnabled})
}

const apiActivateUser = async (userId) => {
  return await axiosInstance.put(`/profiles/${userId}/activate`, { handlerEnabled });
};

const apiDeactivateUser = async (userId) => {
  return await axiosInstance.put(`/profiles/${userId}/deactivate`, { handlerEnabled });
};

export default {
  apiFetchAllUsers,
  apiFetchAllUserForReview,
  apiActivateUser,
  apiDeactivateUser,
};
