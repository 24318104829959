import { Typography } from "@material-ui/core";
import styled from "styled-components";

const HeaderIntroContainer = styled.div`
  padding: 10px;
`;

function HomeIntro() {
  return (
    <HeaderIntroContainer>
      <Typography variant="h6" gutterBottom>
        Welcome Sales Warrior!
      </Typography>

      <Typography gutterBottom>
        This portal is a first beta release. In this current implementation you have an ability to generate marketing
        content for solutions put together specifically to help you sell merchant services via Clover and 3rd party apps
        made by CSI Works.
      </Typography>
    </HeaderIntroContainer>
  );
}

export default HomeIntro;
