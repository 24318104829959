import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Paper,
  Typography,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from "@material-ui/core";

import styled from "styled-components";

import PaperContentHolder from "../../components/PaperContent";

import { playlistByIdRequest } from "../../store/playlist-by-id/PlaylistByIdAction";

import SoundCloudChannelDefault from "../../images/soundcloud-logo.jpg";
import YoutubeChannelDefault from "../../images/youtube-logo.jpg";

const ChannelInfoHolder = styled.div`
  padding: 10px;

  img {
    width: 100%;
  }
`;

function PlaylistDetailsPage() {
  const { id, playlistId } = useParams();

  const playlistById = useSelector((state) => state.playlistById);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(playlistByIdRequest(id, playlistId));
  }, [dispatch, id, playlistId]);

  if (!playlistById.isLoaded) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }

  const playlist = playlistById.playlist;

  return (
    <>
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <PaperContentHolder>
          <Grid container alignItems="stretch" spacing={4}>
            <Grid item xs={12} style={{ alignSelf: "center" }}>
              <Typography variant="h4" component="span">
                {playlist.title}
              </Typography>
              <Typography variant="subtitle1" component="span" style={{ paddingLeft: "50px" }}>
                Exclusive Playlist
              </Typography>
            </Grid>
            <Grid item md={5} xs={12}>
              <ChannelInfoHolder>
                <img
                  src={playlist.format === "video" ? playlist.youTubeCoverUrl : SoundCloudChannelDefault}
                  alt="channel image"
                ></img>
                <Typography variant="subtitle1" color="textSecondary">
                  Number of {playlist.format === "video" ? "Videos" : "Tracks"}: <b>{playlist.numberOfItems}</b>
                </Typography>
                <Typography variant="body1">{playlist.description}</Typography>
              </ChannelInfoHolder>
            </Grid>

            <Grid item md={7} xs={12}>
              <List>
                {playlist.items.map((item, index) => (
                  <Fragment key={index}>
                    {index != 0 ? <Divider /> : null}
                    <a href={item.link} className="plainLink" target="_blank">
                      <ListItem button>
                        <ListItemAvatar>
                          {playlist.format === "video" ? (
                            <img
                              src={item.coverUrl}
                              alt="channel image"
                              style={{ maxWidth: "200px" }}
                            />
                          ) : (
                            <img
                              src={SoundCloudChannelDefault}
                              alt="channel image"
                              style={{ maxHeight: "150px" }}
                            />
                          )}
                        </ListItemAvatar>
                        <ListItemText style={{ marginLeft: "50px" }} primary={item.title} secondary={item.channel} />
                      </ListItem>
                    </a>
                  </Fragment>
                ))}
              </List>
            </Grid>
          </Grid>
        </PaperContentHolder>
      </Paper>
    </>
  );
}

export default PlaylistDetailsPage;
