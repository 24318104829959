import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  LinearProgress,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  ListItemText,
  Paper,
  IconButton,
} from "@material-ui/core";

import { channelByIdRequest } from "../../store/channel-by-id/ChannelByIdAction";
import {
  playlistByIdRequest,
  addItemToPlaylist,
  deleteItemFromPlaylist,
} from "../../store/playlist-by-id/PlaylistByIdAction";

import PaperContentHolder from "../../components/PaperContent";
import DeletePlaylistItemModal from "./DeletePlaylistItemModal";
import EditPlaylistItemModal from "./EditPlaylistItemModal";

import DeleteIcon from "@material-ui/icons/Delete";

import SoundCloudPlaylistDefault from "../../images/soundcloud-logo.jpg";

function ChannelSoundcloudPlaylistsPage() {
  const { id, playlistId } = useParams();

  const channelById = useSelector((state) => state.channelById);
  const playlistById = useSelector((state) => state.playlistById);
  const dispatch = useDispatch();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedToDeleteId, setSelectedToDeleteId] = useState();

  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    dispatch(channelByIdRequest(id));
    dispatch(playlistByIdRequest(id, playlistId));
  }, [dispatch, id, playlistId]);

  if (
    !channelById.isLoaded ||
    channelById.channelId != id ||
    !playlistById.isLoaded ||
    playlistById.loadedPlaylistId != playlistId
  ) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }

  const channel = channelById.channel;
  const playlist = playlistById.playlist;

  if (!channel) {
    return <>Invalid channel id is used</>;
  }

  if (!playlist) {
    return <>Invalid playlist id is used</>;
  }

  return (
    <>
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <PaperContentHolder>
          <DeletePlaylistItemModal
            show={showConfirmDeleteModal}
            onClose={() => setShowConfirmDeleteModal(false)}
            playlist={playlist}
            selectedPlaylistItemId={selectedToDeleteId}
            isDeleting={playlistById.isDeleting}
            deleteAction={() => {
              dispatch(deleteItemFromPlaylist(playlistId, selectedToDeleteId));
            }}
          />
          <EditPlaylistItemModal
            onClose={() => setShowCreateModal(false)}
            show={showCreateModal}
            isUpdating={playlistById.isUpdating}
            onSubmit={(values) => {
              setShowCreateModal(false);

              const payload = {
                channelId: id,
                playlistId,
                title: values.playlistItemTitle,
                description: values.playlistItemDescription,
                link: values.playlistItemLink,
              };
              dispatch(addItemToPlaylist(playlistId, payload));
            }}
          />
          <Typography variant="h4">SoundCloud Account - {channel.title}</Typography>
          <Typography variant="h5">{playlist.title}</Typography>
          <TableContainer component={Paper} style={{ maxHeight: "725px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow></TableRow>
                <TableRow>
                  <TableCell>Track</TableCell>
                  <TableCell width="150px">Link</TableCell>
                  <TableCell align="center" width="220px">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {playlist.items.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={3}>No Playlist Items</TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
                {playlist.items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={SoundCloudPlaylistDefault}
                        alt="test"
                        style={{ width: "100px", height: "100px", marginRight: "30px" }}
                      />
                      <ListItemText primary={item.title} secondary={item.description} />
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <a href={item.link} target="_blank">
                          Click to open
                        </a>
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          setShowConfirmDeleteModal(true);
                          setSelectedToDeleteId(item.id);
                        }}
                      >
                        <DeleteIcon fontSize="large" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={6}>
                    <Container style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ width: "195px" }}
                        onClick={() => {
                          setShowCreateModal(true);
                        }}
                      >
                        Add Playlist Item
                      </Button>
                    </Container>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </PaperContentHolder>
      </Paper>
    </>
  );
}

export default ChannelSoundcloudPlaylistsPage;
