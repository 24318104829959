import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  Link,
  IconButton,
  Hidden,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import {
  HeaderBlankContainer,
  LogoHolder,
  CsiLinkHolder,
  RegisterHolder,
  LogInHolder,
  BurgerMenuHolder,
  MobileNavSpacer,
  PrivacyTermsHolder,
  PrivacyTermsLinkWrapper,
} from "./style";

import Logo from "./Logo";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
    "margin-top": "72px",
  },
  drawerPaperMobile: {
    width: drawerWidth,
    height: "100%",
  },
}));

function Header({ children }) {
  const classes = useStyles();

  const drawerContent = (
    <List>
      <Link href="https://csiworks.net/" target="_blank" style={{ color: "black", textDecoration: "none" }}>
        <ListItem button key={1}>
          <ListItemIcon>
            <HomeIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="CSI Works" />
        </ListItem>
      </Link>
      <Divider />
      <RouterLink to="/register" className="plainLink">
        <ListItem button key={2}>
          <ListItemIcon>
            <PersonIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Register" />
        </ListItem>
      </RouterLink>
      <Divider />
      <RouterLink to="/login" className="plainLink">
        <ListItem button key={3}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Log In" />
        </ListItem>
      </RouterLink>
      <PrivacyTermsHolder>
        <Divider />
        <PrivacyTermsLinkWrapper>
          <RouterLink to="/privacy-policy" target="_blank">
            <Typography>Privacy Policy</Typography>
          </RouterLink>
        </PrivacyTermsLinkWrapper>
      </PrivacyTermsHolder>
    </List>
  );

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container = window !== undefined ? () => window.document.body : undefined;
  return (
    <>
      <HeaderBlankContainer>
        <BurgerMenuHolder>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
            <MenuIcon fontSize="large" />
          </IconButton>
        </BurgerMenuHolder>
        <LogoHolder>
          <RouterLink to="/" className="plainLink">
            <Logo />
          </RouterLink>
        </LogoHolder>

        <CsiLinkHolder>
          <Link href="https://csiworks.net/">
            <Typography variant="subtitle1">CSI Works</Typography>
          </Link>
        </CsiLinkHolder>
        <RegisterHolder>
          <Link component={RouterLink} to="/register">
            <Typography variant="subtitle1">Register</Typography>
          </Link>
        </RegisterHolder>
        <LogInHolder>
          <Link component={RouterLink} to="/login">
            <Typography variant="subtitle1">Log In</Typography>
          </Link>
        </LogInHolder>
      </HeaderBlankContainer>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaperMobile,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div>
            <MobileNavSpacer />
            <Divider />
            <span onClick={handleDrawerToggle}>{drawerContent}</span>
          </div>
        </Drawer>
      </Hidden>
      {children}
    </>
  );
}

export default Header;
