import { axiosInstance } from "../../network/api";
const handlerEnabled = true;

const apiFetchAllPlaylistsForChannel = async (channelId) => {
  return await axiosInstance.get(`/channels/${channelId}/playlists`, { handlerEnabled });
};

const apiEnablePlaylist = async (playlistId) => {
  return await axiosInstance.post(`/playlists/${playlistId}/enable`, { handlerEnabled });
};

const apiDisablePlaylist = async (playlistId) => {
  return await axiosInstance.post(`/playlists/${playlistId}/disable`, { handlerEnabled });
};

const apiDeletePlaylist = async (playlistId) => {
  return await axiosInstance.delete(`/playlists/${playlistId}`, { handlerEnabled });
};

const apiCreatePlaylist = async (payload) => {
  return await axiosInstance.put(`/playlists`, payload, { handlerEnabled });
};

const apiUpdatePlaylist = async (playlistId, payload) => {
  return await axiosInstance.post(`/playlists/${playlistId}`, payload, { handlerEnabled });
};

export default {
  apiFetchAllPlaylistsForChannel,
  apiEnablePlaylist,
  apiDisablePlaylist,
  apiDeletePlaylist,
  apiCreatePlaylist,
  apiUpdatePlaylist,
};
