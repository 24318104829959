import * as TYPES from "./KnowledgeBaseTypes";

const INITIAL_STATE = {
  isLoaded: false,
  searchValues: {
    searchStr: "",
    includeVideo: true,
    includeAudio: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.KNOWLEDGE_BASE_REQUEST:
      return {
        ...state,
      };
    case TYPES.KNOWLEDGE_BASE_RECEIVE:
      return {
        ...state,
        knowledge: action.payload,
        isLoaded: true,
      };

    case TYPES.KNOWLEDGE_SEARCH_VALUES:
      return {
        ...state,
        searchValues: action.searchValues,
      };
    default:
      return state;
  }
};
