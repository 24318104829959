import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import "./DropFileInput.css";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import file from "../../assets/file.jpg";
import file2 from "../../assets/file2.jpg";
import { makeStyles, Typography } from "@material-ui/core";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";

const DropFileInput = ({handleUpload}) => {
  const wrapperRef = useRef(null);
  const [progress, setProgress] = useState()

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploaded, setUploaded] = useState(false)

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      setProgress(Math.round((100 * newFile.loaded) / newFile.total))
        const updatedList = [...uploadedFiles, newFile];
        setUploadedFiles(updatedList);
        setUploaded(true)
    }
    setUploaded(true)
}

const fileRemove = (file) => {
  const updatedList = [...uploadedFiles];
  updatedList.splice(uploadedFiles.indexOf(file), 1);
  setUploadedFiles(updatedList);
}
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#30b0df",
      },
    },
  });
  
  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }

  return (
    <div className="box">
      <Typography
        variant="h5"
        style={{ fontWeight: "normal", color: "#4c4e56", margin: "15px 0px" }}
        align="center"
      >
        File Upload
      </Typography>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={file2} alt="" />
          <p>
            Drag & drop or{" "}
            <span style={{ color: "#30b0df", textDecoration: "underline" }}>
              browse
            </span>{" "}
            your files here
          </p>
        </div>
        <input type="file" value="" onChange={onFileDrop} />
      </div>
      {uploadedFiles.length > 0 ? (
        <>
            {uploadedFiles.map((item, index) => (

              <div id={index} className="drop-file-preview__item">

              <img src={file} alt="" />
            <div className="drop-file-preview__item__info">
              <p>{item.name}</p>
              <MuiThemeProvider theme={theme}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  color="secondary"
                  className="progressBar"
                  style={{
                    width: "100%",
                    marginLeft: "5px",
                  }}
                  />
              </MuiThemeProvider>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{color: 'gray'}} >{bytesToSize(item.size)}</p>
                <p style={{ color: "#2091df" }}>{!uploaded ? `Uploading... ${progress}%` : "File Uploaded" } </p>
              </div>
            </div>
          </div>
            ))
            }
          <div className="button__container">
            <button className="button" onClick={() => handleUpload(uploadedFiles)}>DONE</button>
          </div>
        </>
      ) : null}
      <div style={{padding: '15px'}} >

      </div>
    </div>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
