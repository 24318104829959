import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Button,
  TableHead,
  LinearProgress,
} from "@material-ui/core";

import { usersRequest, usersActivate, usersDeactivate } from "../../store/users/UsersAction";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import PaperContentHolder from "../../components/PaperContent";
import Auth from "../../Auth";
import axios from "axios";
import { BASE_URL } from "../../config";
import { profileUpdate } from "../../store/profile/ProfileAction";
import { useHistory } from "react-router-dom";
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { axiosInstance } from "../../network/api";
import CheckIcon from '@material-ui/icons/Check';
function UsersManagementPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const history = useHistory()
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersRequest());
  }, [dispatch]);

  if (!users.isLoaded) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }

  let rows = users.allUsers;

  if (searchStr) {
    function searchValue(searchable = "", search = "") {
      return searchable.toLowerCase().includes(search.toLowerCase());
    }

    rows = users.allUsers.filter(
      (user) =>
        searchValue(user.firstName, searchStr) ||
        searchValue(user.lastName, searchStr) ||
        searchValue(user.email, searchStr) ||
        searchValue(user.phone, searchStr)
    );
  }


  let emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  if (rows.length < rowsPerPage) {
    emptyRows = 0;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  console.log(users)

  const handleReview = async (fileName) => {
    const res = await axiosInstance.get(`/dox/download?fileName=${fileName}`, {
      responseType: 'blob'
    })
    const fileUrl = URL.createObjectURL(res.data)
    window.open(fileUrl, '_blank')
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleActivate = (userId) => () => {
    dispatch(usersActivate(userId));
  };

  const handleDeactivate = (userId) => () => {
    dispatch(usersDeactivate(userId));
  };

  const currentUserId = Auth.getUser().id;

  return (
    <>
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress style={{ visibility: users.isUpdating ? "visible" : "hidden" }} />
        <PaperContentHolder>
          <TextField
            name="search"
            label="Search"
            variant="outlined"
            fullWidth
            value={searchStr}
            onChange={(e) => {
              setSearchStr(e.target.value);
            }}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Email</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Phone</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6">Wholesaler</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h6">Actions</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
                  (row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        <div style={{display: 'flex', alignItems: 'center'}}>
                       {row.lastName} {row.firstName}
                      
                           
                        </div>
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.phone}</TableCell>
                      <TableCell align="center">
                        {row.status === "Approved" && 
                        <CheckIcon fontSize="30px" />}
                         {row.status === "Rejected" && (
                          <ThumbDownOffAltIcon style={{color: 'red', marginLeft: '5px', fontSize: '14px'}} />
                         )}  
                      </TableCell>
                      <TableCell style={{ width: 300 }} align="right">
                        <div style={{display: 'flex'}}>
                          
                            <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            disabled={users.isUpdating || currentUserId === row.id}
                            style={{marginRight: '10px'}}
                            onClick={() => history.push(`/admin/view/${row.id}`)}
                            >
                            View Profile
                          </Button>
                            
                        {row.activated ? (
                          <Button
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          disabled={users.isUpdating || currentUserId === row.id}
                          onClick={handleDeactivate(row.id)}
                          >
                            Deactivate
                          </Button>
                        ) : (
                          <Button
                          variant="outlined"
                          color="primary"
                          disabled={users.isUpdating || currentUserId === row.id}
                          onClick={handleActivate(row.id)}
                          >
                            Activate
                          </Button>
                        )}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                )}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 69 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            colSpan={4}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 30, { value: -1, label: "All" }]}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </PaperContentHolder>
      </Paper>
    </>
  );
}

export default UsersManagementPage;
