import styled from "styled-components";

import { Link as RouterLink } from "react-router-dom";
import { Link } from "@material-ui/core";

const Footer = styled.div`
  width: 100%;
  min-height: 84px;
  background-color: rgba(52, 52, 60);
`;

const FooterContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr;

  font-family: "Open Sans", Arial, sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 18.2px;
  color: #cccccc;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
    grid-template-columns: 490px auto;
  }

  @media (min-width: 1200px) {
    width: 1170px;
    grid-template-columns: 490px auto;
  }
`;

const Copyright = styled.div`
  margin-left: 15px;
  margin-top: 15px;
  @media (min-width: 992px) {
    margin-left: 0px;
  }
`;

const Links = styled.div`
  margin-top: 15px;
  margin-right: 15px;
  padding-bottom: 15px;
  @media (min-width: 992px) {
    justify-self: end;
  }
`;

const LinkText = styled.span`
  color: #cccccc;
  :hover {
    color: #3f51b5;
  }
`;

const RightsText = styled.div`
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  @media (min-width: 992px) {
    justify-content: space-between;
  }
`;

const VerticalLine = styled.div`
  display: inline-block;
  @media (min-width: 992px) {
    display: none;
  }
`;

const BlankFooter = () => {
  return (
    <Footer>
      <FooterContent>
        <RightsText>AgentStack.net is owned and operated by CSI Works a Sell Seven Company.</RightsText>{" "}
        <Wrapper>
          <Copyright>Copyright 2021 ©&nbsp;All Rights Reserved</Copyright>
          <Links>
            <VerticalLine>&nbsp; | &nbsp;</VerticalLine>

            <Link component={RouterLink} to="/privacy-policy">
              <LinkText>Privacy &amp; Policy</LinkText>
            </Link>
          </Links>
        </Wrapper>
      </FooterContent>
    </Footer>
  );
};

export default BlankFooter;
