import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import styled from "styled-components";

import Auth from "./Auth";

// public pages
import LandingPage from "./pages/landing/LandingPage";
import PrivacyPolicyPage from "./pages/privacy-policy/PrivacyPolicyPage";

// auth pages
import LoginPage from "./pages/login/LoginPage";
import RegistrationPage from "./pages/registration/RegistrationPage";
import RestorePasswordPage from "./pages/restore-password/RestorePasswordPage";
import InvalidEmailConfirmationPage from "./pages/email-confirmation/InvalidEmailConfirmationPage";

// common pages
import ProfilePage from "./pages/profile/ProfilePage";
import ChangePasswordPage from "./pages/change-password/ChangePasswordPage";

// Admin pages
import HomeAdminPage from "./pages/home-admin/HomeAdminPage";
import ChannelsPage from "./pages/channels/ChannelsPage";
import ChannelPlaylistsPage from "./pages/channel-playlists/ChannelPlaylistsPage";
import SoundcloudPlaylistItemsPage from "./pages/channel-playlist-items/SoundcloudPlaylistItemsPage";

import UsersManagementPage from "./pages/users-management/UsersManagementPage";
import YoutubeOauthPage from "./pages/youtube-oauth/YoutubeOauthPage";
import Applications from './pages/applications/ApplicationsList'
// Agent pages
import HomePage from "./pages/home/HomePage";
import KnowledgeBasePage from "./pages/knowledge-base/KnowledgeBasePage";
import PlaylistDetailsPage from "./pages/playlist-details/PlaylistDetailsPage";

import Header from "./components/header/Header";
import HeaderBlank from "./components/header/HeaderBlank";

import "./App.css";
import Buy from "./pages/buy/Buy";
import Scale from "./pages/buy/Scale";
import ByBrand from "./pages/buy/BrandsPage";
import Brand from "./pages/brand/Brand";
import ViewApplication from "./pages/applications/ViewUserProfileAdmin";
import ApplicationList from "./pages/applications/ApplicationsList";
import UserDocuments from "./pages/applications/UserDocuments";

const RootDiv = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: [HeaderRow]70px [ContentRow]auto;
`;

function App() {
  return (
    <RootDiv>
      <Router>
        <Switch>
          {/* auth routes */}
          <Route path="/login/restore">
            <HeaderBlank>
              <RestorePasswordPage />
            </HeaderBlank>
          </Route>
          <Route path="/login">
            <HeaderBlank>
              <LoginPage />
            </HeaderBlank>
          </Route>
          <Route path="/register">
            <HeaderBlank>
              <RegistrationPage />
            </HeaderBlank>
          </Route>
          <Route path="/invalid-email-confirmation">
            <HeaderBlank>
              <InvalidEmailConfirmationPage />
            </HeaderBlank>
          </Route>
          {/* Common routes for all roles */}
          <PrivateRoute path="/profile">
            <Header>
              <ProfilePage />
            </Header>
          </PrivateRoute>
          <PrivateRoute path="/change-password">
            <Header>
              <ChangePasswordPage />
            </Header>
          </PrivateRoute>
          {/* Admin role routes */}
          <PrivateRoute path="/admin/channels/youtube/oauth" onlyAdmin>
            <Header>
              <YoutubeOauthPage />
            </Header>
          </PrivateRoute>
          <PrivateRoute path="/admin/channels/soundcloud/:id/playlists/:playlistId" onlyAdmin>
            <Header>
              <SoundcloudPlaylistItemsPage />
            </Header>
          </PrivateRoute>
          <PrivateRoute path="/admin/channels/:type/:id" onlyAdmin>
            <Header>
              <ChannelPlaylistsPage />
            </Header>
          </PrivateRoute>
          <PrivateRoute path="/admin/channels/:type" onlyAdmin>
            <Header>
              <ChannelsPage />
            </Header>
          </PrivateRoute>
          <PrivateRoute path="/admin/users" onlyAdmin>
            <Header>
              <UsersManagementPage />
            </Header>
          </PrivateRoute>
          <PrivateRoute exact path="/admin/applications" onlyAdmin>
            <Header>
              <ApplicationList />
            </Header>
          </PrivateRoute>
          <PrivateRoute exact path="/admin/applications/by-user/:userId" onlyAdmin>
            <Header>
              <UserDocuments />
            </Header>
          </PrivateRoute>
          <PrivateRoute exact path="/admin/view/:userId" onlyAdmin>
            <Header>
              <ViewApplication />
            </Header>
          </PrivateRoute>
          <PrivateRoute path="/admin" onlyAdmin>
            <Header>
              <HomeAdminPage />
            </Header>
          </PrivateRoute>
          {/* Agent role routes */}
          <PrivateRoute path="/knowledge-base/channel/:id/playlist/:playlistId">
            <Header>
              <PlaylistDetailsPage />
            </Header>
          </PrivateRoute>
          <PrivateRoute path="/knowledge-base">
            <Header>
              <KnowledgeBasePage />
            </Header>
          </PrivateRoute>
          <PrivateRoute path="/home">
            <Header>
              <HomePage />
            </Header>
          </PrivateRoute>
          <PrivateRoute exact path="/buy">
            <Header>
              <Buy />
            </Header>
          </PrivateRoute>
          <PrivateRoute exact path="/scale">
            <Header>
              <Scale />
            </Header>
          </PrivateRoute>
          <PrivateRoute exact path="/scale/brands">
            <Header>
              <ByBrand />
            </Header>
          </PrivateRoute>
          <PrivateRoute exact path="/scale/brands/:brandName">
            <Header>
              <Brand />
            </Header>
          </PrivateRoute>

          {/* Landing page */}
          <Route path="/privacy-policy">
            <HeaderBlank>
              <PrivacyPolicyPage />
            </HeaderBlank>
          </Route>
          <Route path="/">
            <HeaderBlank>
              <LandingPage />
            </HeaderBlank>
          </Route>
        </Switch>
      </Router>
    </RootDiv>
  );
}

const PrivateRoute = ({ children, onlyAdmin, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => {
        const isLoggedIn = Auth.isLoggedIn();

        if (!isLoggedIn) {
          return <Redirect to={`/login`} />;
        }
        const { user } = Auth.getUser();
        if (onlyAdmin && user?.role === "admin") {
          return <Redirect to={`/home`} />;
        }
        return <>{children}</>;
      }}
    />
  );
};

export default App;
