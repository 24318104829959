import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Container, Grid, Button, Box, Typography, LinearProgress, Link, Paper } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import { profileChangePassword } from "../../store/profile/ProfileAction";

import PaperContentHolder from "../../components/PaperContent";

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().min(6, "Min 6 characters long").required("Required"),
  newPassword: Yup.string().min(6, "Min 6 characters long").required("Required"),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords don't match")
    .required("Required"),
});

function ChangePasswordPage() {
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const [savingNewPassword, setSavingNewPassword] = useState(false);

  if (!profile.isLoaded) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }

  const errors = {
    oldPassword: profile.error,
  };

  const showCompletedMessage = savingNewPassword && !profile.error && !profile.isUpdating;

  return (
    <Paper square={true} variant="outlined" style={{ height: "100%" }}>
      <LinearProgress style={{ visibility: profile.isUpdating ? "visible" : "hidden" }} />
      <PaperContentHolder>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
          }}
          initialErrors={errors}
          enableReinitialize
          validationSchema={ChangePasswordSchema}
          onSubmit={(values) => {
            dispatch(profileChangePassword(profile.id, values));
            setSavingNewPassword(true);
          }}
        >
          {({ submitForm }) => (
            <Container style={{ marginTop: "20px" }} maxWidth="xs">
              <Form>
                <Grid container justify="center" spacing={2}>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography component="div">
                      <Box fontSize="h6.fontSize" style={{ color: "#0093c4" }}>
                        Change password
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="oldPassword"
                      label="Old Password"
                      type="password"
                      variant="outlined"
                      disabled={profile.isUpdating}
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="newPassword"
                      label="New Password"
                      type="password"
                      variant="outlined"
                      disabled={profile.isUpdating}
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="newPasswordConfirm"
                      label="Confirm Password"
                      type="password"
                      variant="outlined"
                      disabled={profile.isUpdating}
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography color="primary" style={{ visibility: showCompletedMessage ? "visible" : "hidden" }}>
                      Password was changed successfully
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={profile.isUpdating}
                      onClick={submitForm}
                      style={{ width: "195px" }}
                    >
                      Save Password
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Link component={RouterLink} to="/profile">
                      <Typography>Cancel</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          )}
        </Formik>
      </PaperContentHolder>
    </Paper>
  );
}

export default ChangePasswordPage;
