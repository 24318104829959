import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, LinearProgress, Paper, Typography } from "@material-ui/core";

import { oauthRequest } from "../../store/youtube-oauth/YoutubeOauthAction";

function YoutubeOauthPage() {
  const history = useHistory();

  const youtubeOauth = useSelector((state) => state.youtubeOauth);
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1));
    const code = params.get("code");

    dispatch(oauthRequest({ code }));
  }, [dispatch]);

  if (youtubeOauth.isSend) {
    history.push("/admin/channels/youtube");
  }

  return (
    <Paper square={true} variant="outlined" style={{ height: "100%" }}>
      <LinearProgress />
      <Grid container justifyContent="center" style={{ marginTop: "30px" }}>
        <Grid item>
          <Typography>Getting access to YouTube data...</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default YoutubeOauthPage;
