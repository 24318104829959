import { Container, Grid, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

function InvalidEmailConfirmationPage() {
  return (
    <>
      <Container style={{ marginTop: "20px" }} maxWidth="xs">
        <Grid container justify="center" direction="column" spacing={2}>
          <Grid item style={{ textAlign: "center" }}>
            <Typography color="secondary">
              Invalid email confirmation token.
              <br /> Email was not verified.
            </Typography>
          </Grid>

          <Grid item style={{ textAlign: "center" }}>
            <Link component={RouterLink} to="/login">
              <Typography>Back to Log In</Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default InvalidEmailConfirmationPage;
