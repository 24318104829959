import { CircularProgress, LinearProgress, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { BASE_URL } from "../../config";
import { axiosInstance } from "../../network/api";

const UserDocuments = () => {
  const { userId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [profile, setProfile] = useState({})
  const [loading, setLoading] = useState(false)
  async function fetchDocuments() {
    const res = await axiosInstance.get(`/dox/${userId}`);
    setDocuments(res.data);
    console.log(res.data);
  }
  useEffect(() => {
    fetchDocuments();
  }, []);
  useEffect(() => {
    async function fetchProfile(){
        setLoading(true)
      const res = await axiosInstance.get(`/profiles/${userId}`);
      setProfile(res.data)
      setLoading(false)
    }
    fetchProfile()
}, []);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function getFileExtension(fileName) {
    return fileName.split(".").pop();
  }
  function handleClick(link) {
    const win = window.open(link, "_blank");
    win.focus();
  }
  if(loading){
      return (
        <LinearProgress
        style={{ visibility: loading ? "visible" : "hidden" }}
          />
      )
  }
  
  return (
    <div>
        {
            documents.length > 0 ?

            <Typography variant="h4" align="center" gutterBottom>
            {profile?.firstName} {profile?.lastName + "'s documents"}
        </Typography>
        :
        <Typography variant="h4" align="center" gutterBottom>No documents for this user</Typography>
    }
        <hr />
      {documents.map((doc) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {getFileExtension(doc.fileName) === "pdf" ? (
            <div
              style={{
                width: "100%",
                height: "800px",
                cursor: "pointer",
                marginTop: "25px",
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
              onClick={() => handleClick(doc.link)}
            >
              <Typography variant="h5" style={{ marginBottom: "10px" }}>
                Open in new tab <OpenInNewIcon fontSize="14px" />{" "}
              </Typography>
              <object
                width="100%"
                height="750px"
                data={doc.link}
                type="application/pdf"
              ></object>
              <hr />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <a href={doc.link} target="">
                <img
                  src={doc.link}
                  style={{
                    width: "100%",
                    height: "unset",
                    margin: "25px 0px",
                  }}
                  alt="document"
                />
              </a>
              <hr />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default UserDocuments;
