import * as TYPES from "./UsersTypes";

const INITIAL_STATE = {
  isLoaded: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.USERS_REQUEST:
      return {
        ...state,
        ...action.payload,
      };

    case TYPES.USERS_RECEIVE:
      return {
        ...state,
        allUsers: action.payload,
        isLoaded: true,
        isUpdating: false,
      };
    case TYPES.USERS_ACTIVATE:
      return {
        ...state,
        isUpdating: true,
      };
    case TYPES.USERS_ACTIVATE_COMPLETED:
      return {
        ...state,
        allUsers: markActivated(state.allUsers, action.userId),
        isUpdating: false,
      };
    case TYPES.USERS_DEACTIVATE:
      return {
        ...state,
        isUpdating: true,
      };
    case TYPES.USERS_DEACTIVATE_COMPLETED:
      return {
        ...state,
        allUsers: markDeactivated(state.allUsers, action.userId),
        isUpdating: false,
      };
    default:
      return state;
  }
};

function markActivated(allUsers, userId) {
  const user = allUsers.find((user) => user.id === userId);
  user.activated = true;

  const result = [];
  result.push(...allUsers);
  return result;
}

function markDeactivated(allUsers, userId) {
  const user = allUsers.find((user) => user.id === userId);
  user.activated = false;

  const result = [];
  result.push(...allUsers);
  return result;
}
