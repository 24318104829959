import * as TYPES from "./UsersTypes";

export const usersRequest = (profileId) => ({
  type: TYPES.USERS_REQUEST,
  profileId,
});

export const usersReviewRequest = (profileId) => ({
  type: TYPES.USERS_REVIEW_REQUEST,
  profileId,
});

export const usersReceive = (payload) => ({
  type: TYPES.USERS_RECEIVE,
  payload,
});

export const reviewsReceive = (payload) => ({
  type: TYPES.REVIEWS_RECEIVE,
  payload,
});

export const usersActivate = (userId) => ({
  type: TYPES.USERS_ACTIVATE,
  userId,
});

export const usersActivateCompleted = (userId) => ({
  type: TYPES.USERS_ACTIVATE_COMPLETED,
  userId,
});

export const usersDeactivate = (userId) => ({
  type: TYPES.USERS_DEACTIVATE,
  userId,
});

export const usersDeactivateCompleted = (userId) => ({
  type: TYPES.USERS_DEACTIVATE_COMPLETED,
  userId,
});
