import * as TYPES from "./ProfileTypes";

const INITIAL_STATE = {
  isLoaded: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.PROFILE_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case TYPES.PROFILE_RECEIVE:
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
        isUpdating: false,
      };
    case TYPES.PROFILE_UPDATE:
      return {
        ...state,
        ...action.payload,
        isUpdating: true,
      };
    case TYPES.PROFILE_CHANGING_PASSWORD:
      return {
        ...state,
        ...action.payload,
        error: null,
        isUpdating: true,
      };
    case TYPES.PROFILE_CHANGED_PASSWORD:
      return {
        ...state,
        ...action.payload,
        error: null,
        isUpdating: false,
      };
    case TYPES.PROFILE_CHANGING_PASSWORD_ERROR:
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    case TYPES.PROFILE_CLEAN_ON_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
