import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  TextField,
  Checkbox,
  LinearProgress,
  FormControlLabel,
  Grid,
  Hidden,
} from "@material-ui/core";

import PaperContentHolder from "../../components/PaperContent";
import PlaylistCard from "../../components/PlaylistCard";

import { knowledgeBaseRequest, knowledgeBaseSearchValues } from "../../store/knowledge-base/KnowledgeBaseAction";

function KnowledgeBasePage() {
  const knowledgeBase = useSelector((state) => state.knowledgeBase);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(knowledgeBaseRequest());
  }, [dispatch]);

  if (!knowledgeBase.isLoaded) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }

  function filterResults() {
    const { searchStr, includeVideo, includeAudio } = knowledgeBase.searchValues;
    let tempList = [];
    if (includeVideo) {
      tempList.push(...knowledgeBase.knowledge.filter((item) => item.format === "video"));
    }
    if (includeAudio) {
      tempList.push(...knowledgeBase.knowledge.filter((item) => item.format === "audio"));
    }

    tempList = tempList.filter((item) => {
      const titleFormatted = item.title.toLowerCase();
      const descriptionFormatted = item.description.toLowerCase();

      const searchStrFormatted = searchStr.toLowerCase();

      return titleFormatted.includes(searchStrFormatted) || descriptionFormatted.includes(searchStrFormatted);
    });

    return tempList;
  }

  const itemsList = filterResults();

  function updateSearchValues(updateObj) {
    dispatch(
      knowledgeBaseSearchValues({
        ...knowledgeBase.searchValues,
        ...updateObj,
      })
    );
  }

  return (
    <>
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <PaperContentHolder>
          <Grid container alignItems="stretch" spacing={4}>
            <Grid item sm={12}>
              <Typography variant="h4">Knowledge Base / Resources</Typography>
            </Grid>
            <Hidden mdDown>
              <Grid item xl={3} lg={1}></Grid>
            </Hidden>
            <Grid item xl={4} lg={8} md={8} sm={7} xs={12}>
              <TextField
                name="search"
                label="Search"
                variant="outlined"
                fullWidth
                value={knowledgeBase.searchValues.searchStr}
                onChange={(e) => {
                  updateSearchValues({ searchStr: e.target.value });
                }}
              />
            </Grid>
            <Grid item xl={2} lg={3} md={4} sm={5} xs={12} style={{ alignSelf: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="includeVideo"
                    color="primary"
                    checked={knowledgeBase.searchValues.includeVideo}
                    onChange={(e) => {
                      updateSearchValues({ includeVideo: e.target.checked });
                    }}
                  />
                }
                label="Video"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="includeAudio"
                    color="primary"
                    checked={knowledgeBase.searchValues.includeAudio}
                    onChange={(e) => {
                      updateSearchValues({ includeAudio: e.target.checked });
                    }}
                  />
                }
                label="Audio"
              />
            </Grid>
            <Hidden lgDown>
              <Grid item xl={3}></Grid>
            </Hidden>

            {itemsList.map((item) => (
              <Grid item xl={3} lg={4} md={6} sm={12} key={item.id}>
                <PlaylistCard item={item} />
              </Grid>
            ))}
          </Grid>
        </PaperContentHolder>
      </Paper>
    </>
  );
}

export default KnowledgeBasePage;
