import * as TYPES from "./KnowledgeBaseTypes";

export const knowledgeBaseRequest = () => ({
  type: TYPES.KNOWLEDGE_BASE_REQUEST,
});

export const knowledgeBaseReceive = (payload) => ({
  type: TYPES.KNOWLEDGE_BASE_RECEIVE,
  payload,
});

export const knowledgeBaseSearchValues = (searchValues) => ({
  type: TYPES.KNOWLEDGE_SEARCH_VALUES,
  searchValues,
});
