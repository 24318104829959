import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Button,
  TableHead,
  LinearProgress,
} from "@material-ui/core";
import {
  usersRequest,
  usersActivate,
  usersDeactivate,
} from "../../store/users/UsersAction";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PaperContentHolder from "../../components/PaperContent";
import Auth from "../../Auth";
import axios from "axios";
import { BASE_URL } from "../../config";
import { profileUpdate } from "../../store/profile/ProfileAction";
import { useHistory } from "react-router-dom";
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { axiosInstance } from "../../network/api";
function ApplicationsList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const history = useHistory()
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersRequest());
  }, [dispatch]);

  if (!users.isLoaded) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }
  let rows = users.allUsers;

  if (searchStr) {
    function searchValue(searchable = "", search = "") {
      return searchable.toLowerCase().includes(search.toLowerCase());
    }
    rows = users.allUsers.filter(
      (user) =>
        searchValue(user.firstName, searchStr) ||
        searchValue(user.lastName, searchStr) ||
        searchValue(user.email, searchStr) ||
        searchValue(user.phone, searchStr) 
    );
  }

  let emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  if (rows.length < rowsPerPage) {
    emptyRows = 0;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  console.log(users);

  const handleReview = async (userId) => {
        history.push(`/admin/applications/by-user/${userId}`)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApprove = async (userId) => {
    let element = document.getElementById(`approve${userId}`)
    element.textContent = "Documents Approved"
    element.style.border = "green 1px solid"
    element.style.color = "green"
    await axiosInstance.post(`/profiles/approve/${userId}`)

  }

  const handleReject = async (userId) => {
    await axiosInstance.post(`/profiles/reject/${userId}`)
    let element = document.getElementById(`reject${userId}`)
    element.textContent = "Documents Rejected"
    element.style.border = "red 1px solid"
    element.style.color = "red"
  }

  const currentUserId = Auth.getUser().id;

  return (
    <>
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress
          style={{ visibility: users.isUpdating ? "visible" : "hidden" }}
        />
        <PaperContentHolder>
          <TextField
            name="search"
            label="Search"
            variant="outlined"
            fullWidth
            value={searchStr}
            onChange={(e) => {
              setSearchStr(e.target.value);
            }}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Email</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Actions</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rows.length < rowsPerPage
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).filter((row) => row.status === "onReview").map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {row.lastName} {row.firstName}
                      </div>
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell style={{ width: '500px' }} align="right">
                      <div style={{ display: "flex" }} >
                        {row.status === "onReview" && (
                          <Button
                            variant="outlined"
                            color="inherited"
                            size="small"
                            disabled={
                              users.isUpdating || currentUserId === row.id
                            }
                            style={{ marginRight: "10px" }}
                            onClick={() => handleReview(row.id)}
                          >
                            View documents
                          </Button>
                         )} 
                       
                          <>
                            <Button
                              id={`approve${row.id}`}
                              variant="outlined"
                              color="primary"
                              disabled={
                                users.isUpdating || currentUserId === row.id
                              }
                              onClick={() => handleApprove(row.id)}
                              size="small"
                              style={{marginRight: '10px'}}
                            >
                              Approve Documents
                            </Button>
                            <Button
                              variant="outlined"
                              id={`reject${row.id}`}
                              color="secondary"
                              disabled={
                                users.isUpdating || currentUserId === row.id
                              }
                              onClick={() => handleReject(row.id)}
                            >
                              Reject Documents
                            </Button>
                          </>
                       
                      </div>
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 69 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            colSpan={4}
            component="div"
            style={{display: rows.count > 10 ? '' : 'none'}}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 30, { value: -1, label: "All" }]}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </PaperContentHolder>
      </Paper>
    </>
  );
}

export default ApplicationsList;


