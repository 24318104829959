import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";

export default function DeletePlaylistModal({ onClose, show, selectedPlaylist, isDeleting, deleteAction }) {
  return (
    <Dialog
      open={show || isDeleting}
      disableBackdropClick={isDeleting}
      disableEscapeKeyDown={isDeleting}
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title">
        Confirm delete
        {isDeleting ? <LinearProgress color="secondary" /> : <LinearProgress style={{ visibility: "hidden" }} />}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure that you want to delete playlist <b>"{selectedPlaylist && selectedPlaylist.title}"</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isDeleting} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onClose();
            deleteAction();
          }}
          disabled={isDeleting}
          color="secondary"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
