import { Grid, LinearProgress, Paper, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Auth from "../../Auth";
import { profileRequest } from "../../store/profile/ProfileAction";

const Buy = () => {
    const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { user } = Auth.getUser();
  useEffect(() => {
    dispatch(profileRequest(Auth.getUser().id));
  }, [dispatch]);

  if (!profile.isLoaded) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }
    return(

    <Grid
        container
        justifyContent="flex-start"
        style={{ flexDirection: "column" }}
        spacing={2}
    >
            <Typography variant="h4" style={{ margin: "0px 10px" }}>
                Buy Equipment In Bulk
            </Typography>
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    variant="subtitle1"
                    style={{ margin: "0px 10px" }}
                    gutterBottom
                >
                    When you source hardware equipment and accessories from AgentStack you
                    earn income and ensure that your merchants get 100% support and
                    training on equipment you sell it. Just register the hardware you
                    onboard.
                </Typography>
                <TextField
                    name="search"
                    label="Search"
                    style={{ width: "300px", margin: "0px 20px" }}
                    size="small"
                    variant="outlined"
                    onChange={(e) => { } } />
            </div>
            <hr />
            {profile.status === "Approved" &&
                <div style={{display: 'flex', height: '15vh', alignItems: 'center', justifyContent: 'center'}}>
                    <a href="https://scale4clover.com/" target="_blank" style={{color: 'inherit'}}>
                    <Typography style={{ fontWeight: "bold", textDecoration: "underline" }}>
                        {"-->"} Buy Scale For Clover
                    </Typography>
                    </a>
                   </div> }
                {
                    profile.status === null || profile.status === "" ?
                    <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginTop: "15px" }}
                    >
                    <Typography style={{ color: "red", margin: "0px 15px" }}>
                        !Your account is not ready to buy in bulk
                    </Typography>
                    <Link style={{ color: "blue" }} to="/profile">
                        Provide required account information
                    </Link>
                </Grid> : null
                }
                {
                      profile.status === "onReview" &&
                      <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ marginTop: "15px" }}
                      >
                      <Typography style={{ color: "#30b0df", margin: "0px 15px" }}>
                          We are reviewing your documents. Please wait for our response.
                      </Typography>
                     
                  </Grid>
                }
        </Grid>
    )  
};

export default Buy;
