import { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Paper,
} from "@material-ui/core";

import DeleteChannelModal from "./DeleteChannelModal";
import EditChannelModal from "./EditChannelModal";

import PaperContentHolder from "../../components/PaperContent";
import { allChannelsRequest, updateChannel, deleteChannel } from "../../store/channels/ChannelsAction";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import YoutubeChannelDefault from "../../images/youtube-logo.jpg";

function ChannelsYoutubePage() {
  const channels = useSelector((state) => state.channels);
  const dispatch = useDispatch();

  const history = useHistory();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedToDeleteId, setSelectedToDeleteId] = useState();

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedToUpdateId, setSelectedToUpdateId] = useState(null);

  const [showLoginWithGoogleModal, setShowLoginWithGoogleModal] = useState(false);
  const [redirectToGoogle, setRedirectToGoogle] = useState(false);

  useEffect(() => {
    dispatch(allChannelsRequest());
  }, [dispatch]);

  if (!channels.isLoaded) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }

  if (redirectToGoogle) {
    const clientId = "162097935565-35vjgsoe5eum6dqg2p1vneguaho4hef5.apps.googleusercontent.com";
    const callbackUrl = encodeURIComponent(`${document.location.origin}/admin/channels/youtube/oauth`);
    const scope = "https://www.googleapis.com/auth/youtube.readonly";
    const params = `client_id=${clientId}&redirect_uri=${callbackUrl}&scope=${scope}&response_type=code&access_type=offline&prompt=consent`;
    const googleOauthUrl = `https://accounts.google.com/o/oauth2/auth?${params}`;
    window.location.href = googleOauthUrl;
  }

  const youtubeChannels = channels.allChannels.filter((ch) => ch.type === "youtube");
  const selectedToDelete = youtubeChannels.find((channel) => channel.id === selectedToDeleteId);
  const selectedToUpdate = youtubeChannels.find((channel) => channel.id === selectedToUpdateId);

  return (
    <>
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <PaperContentHolder>
          <DeleteChannelModal
            show={showConfirmDeleteModal}
            onClose={() => setShowConfirmDeleteModal(false)}
            selectedChannel={selectedToDelete}
            isDeleting={channels.isDeleting}
            deleteAction={() => {
              dispatch(deleteChannel(selectedToDeleteId));
            }}
          />
          <EditChannelModal
            onClose={() => setShowUpdateModal(false)}
            show={showUpdateModal}
            selectedChannel={selectedToUpdate}
            isUpdating={channels.isUpdating}
            onSubmit={(values) => {
              setShowUpdateModal(false);

              const payload = {
                type: "youtube",
                youTubeId: selectedToUpdate.youTubeId,
                ...values,
              };
              dispatch(updateChannel(selectedToUpdateId, payload));
            }}
          />
          <GoogleAuthModal
            show={showLoginWithGoogleModal}
            onClose={() => setShowLoginWithGoogleModal(false)}
            isRedirecting={redirectToGoogle}
            redirectAction={() => {
              setRedirectToGoogle(true);
            }}
          />
          <Typography variant="h4">YouTube Account</Typography>
          <List>
            <Divider />
            {youtubeChannels.map((channel, index) => (
              <Fragment key={index}>
                <ListItem
                  button
                  onClick={() => {
                    history.push(`/admin/channels/youtube/${channel.id}`);
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={YoutubeChannelDefault}
                      alt="test"
                      style={{ width: "200px", height: "200px", marginRight: "30px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={channel.title}
                    secondary={`${channel.numberOfItems} Videos`}
                    primaryTypographyProps={{ variant: "h5" }}
                  />
                </ListItem>
                <Divider />
              </Fragment>
            ))}
            <Divider />
            <ListItem>
              <Container style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ width: "400px" }}
                  onClick={() => {
                    setShowLoginWithGoogleModal(true);
                  }}
                >
                  Add Channels from Google Account
                </Button>
              </Container>
            </ListItem>
          </List>
        </PaperContentHolder>
      </Paper>
    </>
  );
}

function GoogleAuthModal({ onClose = () => {}, show = false, isRedirecting = false, redirectAction = () => {} }) {
  return (
    <Dialog open={show} disableBackdropClick={isRedirecting} disableEscapeKeyDown={isRedirecting} onClose={onClose}>
      <DialogTitle>Log In via Google Account</DialogTitle>
      <DialogContent>
        <DialogContentText>In order to add new channels you need to authenticate via Google Account</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isRedirecting} color="primary">
          Cancel
        </Button>
        <Button onClick={redirectAction} disabled={isRedirecting} color="secondary" autoFocus>
          Go to Google
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChannelsYoutubePage;
