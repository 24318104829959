import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

const PlaylistSchema = Yup.object().shape({
  title: Yup.string().max(100, "Title cannot exceed 100 characters").required("Required"),
  description: Yup.string().max(5000, "Description cannot exceed 5000 characters").required("Required"),
});

export default function EditPlaylistModal({ onClose, show, selectedPlaylist, isUpdating, onSubmit }) {
  return (
    <Dialog
      fullWidth={true}
      open={show || isUpdating}
      disableBackdropClick={isUpdating}
      disableEscapeKeyDown={isUpdating}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          title: (selectedPlaylist && selectedPlaylist.title) || "",
          description: (selectedPlaylist && selectedPlaylist.description) || "",
        }}
        validationSchema={PlaylistSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm }) => (
          <>
            <Form>
              <DialogTitle id="alert-dialog-title">
                {selectedPlaylist ? "Update playlist" : "Create new playlist"}
                {isUpdating ? <LinearProgress /> : <LinearProgress style={{ visibility: "hidden" }} />}
              </DialogTitle>
              <DialogContent>
                <Field
                  component={TextField}
                  name="title"
                  label="Title"
                  placeholder="Playlist name"
                  variant="outlined"
                  disabled={isUpdating}
                  required
                  fullWidth
                  style={{ minHeight: "5rem", width: "100%" }}
                />
                <Field
                  component={TextField}
                  name="description"
                  label="Description"
                  placeholder="Playlist description"
                  multiline
                  rows={10}
                  variant="outlined"
                  disabled={isUpdating}
                  required
                  fullWidth
                  style={{ minHeight: "16rem", width: "100%" }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} disabled={isUpdating} color="primary">
                  Cancel
                </Button>
                <Button onClick={submitForm} disabled={isUpdating} color="primary" autoFocus>
                  Save
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
