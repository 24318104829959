import { Card, Grid } from "@material-ui/core";

import HomeIntro from "./HomeIntro";
import ExclusiveVideos from "../../components/exclusive-videos/ExclusiveVideos";

function HomePage() {
  return (
    <>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xl={9} lg={8} md={6} sm={12}>
          <Card>
            <HomeIntro />
          </Card>
        </Grid>

        <Grid item xl={3} lg={4} md={6} sm={12}>
          <Card>
            <ExclusiveVideos />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default HomePage;
