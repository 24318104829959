import * as TYPES from "./ChannelsTypes";

const INITIAL_STATE = {
  allChannels: [],
  isLoaded: false,
  isUpdating: false,
  isDeleting: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.CHANNELS_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case TYPES.CHANNELS_RECEIVE:
      return {
        ...state,
        allChannels: action.payload,
        isLoaded: true,
        isUpdating: false,
      };
    case TYPES.CHANNEL_CREATE:
      return {
        ...state,
        isUpdating: true,
      };
    case TYPES.CHANNEL_CREATE_COMPLETED:
      return {
        ...state,
        allChannels: [...state.allChannels, action.payload],
        isUpdating: false,
      };
    case TYPES.CHANNEL_UPDATE:
      return {
        ...state,
        isUpdating: true,
      };
    case TYPES.CHANNEL_UPDATE_COMPLETED:
      const index = state.allChannels.findIndex((ch) => ch.id === action.payload.id);
      state.allChannels[index] = action.payload;
      return {
        ...state,
        isUpdating: false,
      };
    case TYPES.CHANNEL_DELETE:
      return {
        ...state,
        isDeleting: true,
      };
    case TYPES.CHANNEL_DELETE_COMPLETED:
      return {
        ...state,
        allChannels: state.allChannels.filter((ch) => ch.id !== action.channelId),
        isDeleting: false,
      };
    default:
      return state;
  }
};
