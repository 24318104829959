export const CHANNELS_REQUEST = "CHANNELS_REQUEST";
export const CHANNELS_RECEIVE = "CHANNELS_RECEIVE";

export const CHANNEL_CREATE = "CHANNEL_CREATE";
export const CHANNEL_CREATE_COMPLETED = "CHANNEL_CREATE_COMPLETED";

export const CHANNEL_UPDATE = "CHANNEL_UPDATE";
export const CHANNEL_UPDATE_COMPLETED = "CHANNEL_UPDATE_COMPLETED";

export const CHANNEL_DELETE = "CHANNEL_DELETE";
export const CHANNEL_DELETE_COMPLETED = "CHANNEL_DELETE_COMPLETED";
