import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  Box,
  Typography,
  LinearProgress,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import Auth from "../../Auth";
import {
  profileRequest,
  profileUpdate,
} from "../../store/profile/ProfileAction";

import PaperContentHolder from "../../components/PaperContent";
import { useState } from "react";
import DropFileInput from "../../components/dnd/DropFileInput";
import axios from "axios";
import { BASE_URL } from "../../config";
import { useParams } from "react-router-dom";
import apiFetchProfile from "../../store/profile/ProfileApis";
import "./ViewApplication.css";
import { axiosInstance } from "../../network/api";

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().min(14, "Incomplete phone number").required("Required"),
});

function ViewUserProfileAdmin() {
  const [profile, setProfile] = useState({});
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [files, setFiles] = useState([]);
  const [reviewMessage, setReviewMessage] = useState(false);
  const [documents, setDocuments] = useState([]);
  const { user } = Auth.getUser();
  const onFileChange = (files) => {
    setFiles(files);
  };
  useEffect(() => {
    async function fetchProfile() {
      const res = await axiosInstance.get(`/profiles/${userId}`, {});
      setProfile(res.data);
    }
    fetchProfile();
  }, [profile.status]);
  async function fetchDocuments() {
    const res = await axiosInstance.get(`/dox/${userId}`);
    setDocuments(res.data);
    console.log(res.data);
  }
  useEffect(() => {
    fetchDocuments();
  }, []);
  console.log(profile);
  console.log("user", user);

  const handleUpload = async (file) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("userId", profile?.id);
    await axiosInstance.post(`/dox/upload`, formData, {
      "Content-Type": "multipart/form-data",
    });
    profile.status = "OnReview";
  };

  function getFileExtension(fileName) {
    return fileName.split(".").pop();
  }

  return (
    <Paper
      square={true}
      variant="outlined"
      style={{ height: "100%", display: "flex", justifyContent: "flex-start" }}
    >
      <PaperContentHolder>
        <Container style={{ marginTop: "10px" }} maxWidth="md">
          <Typography variant="h4">User Profile</Typography>

          <Typography
            variant="h6"
            style={{ marginTop: "30px", color: "#30b0df" }}
          >
            Agent Information
          </Typography>
          <FormControl>
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}></Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  placeholder="John"
                  variant="outlined"
                  required
                  value={profile?.firstName}
                  fullWidth
                  style={{ minHeight: "5rem" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastName"
                  placeholder="Smith"
                  variant="outlined"
                  required
                  value={profile?.lastName}
                  fullWidth
                  style={{ minHeight: "5rem" }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type="email"
                  placeholder="example@email.com"
                  variant="outlined"
                  value={profile?.email}
                  required
                  fullWidth
                  style={{ minHeight: "5rem" }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MuiPhoneNumber
                  id="phone"
                  name="phone"
                  variant="outlined"
                  disableCountryCode={true}
                  value={profile?.phone}
                  disableDropdown={true}
                  onlyCountries={["us", "ca"]}
                  defaultCountry="us"
                  required
                  style={{ minHeight: "5rem" }}
                />
                <FormControl style={{ minWidth: "200px", marginLeft: "20px" }}>
                  <InputLabel id="select-label">Select Phone Type</InputLabel>
                  <Select
                    name="phoneType"
                    labelId="select-label"
                    label="Label"
                    value={profile?.phoneType}
                  >
                    <MenuItem value="Land Line">Land Line</MenuItem>
                    <MenuItem value="Mobile">Mobile</MenuItem>
                    <MenuItem value="VOIP">VOIP</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container direction="column">
                <Typography
                  variant="h6"
                  style={{ marginTop: "30px", color: "#30b0df" }}
                >
                  Company Information
                </Typography>
                <Grid
                  style={{ marginTop: "15px" }}
                  container
                  spacing={2}
                  className="companyInfo"
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="companyName"
                      label="Company Name"
                      placeholder="Company Name"
                      variant="outlined"
                      value={profile?.companyName}
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="companyUrl"
                      label="URL"
                      placeholder="URL"
                      variant="outlined"
                      value={profile?.companyUrl}
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <Typography variant="h6" style={{ marginRight: "30px" }}>
                    Reseller Permit Provided:{" "}
                    {profile?.status === null || profile?.status === "" ? (
                      <span
                        style={{
                          color: "#30b0df",
                          padding: 0,
                          backgroundColor: "white",
                          fontSize: "20px",
                          textDecoration: "underline",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        No
                      </span>
                    ) : null}
                    {profile?.status === "onReview" && (
                      <span
                        style={{
                          color: "#30b0df",
                          fontSize: "20px",
                          textDecoration: "underline",
                        }}
                      >
                        On Review
                      </span>
                    )}
                    {profile?.status === "Rejected" && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "20px",
                          textDecoration: "underline",
                        }}
                      >
                        Rejected
                      </span>
                    )}
                    {profile?.status === "Approved" && (
                      <span
                        style={{
                          color: "#30b0df",
                          fontSize: "20px",
                          textDecoration: "underline",
                        }}
                      >
                        Approved
                      </span>
                    )}
                  </Typography>
                </div>
                <Grid container className="doxContainer" direction="column">

                  {documents && (
                    <Typography
                    variant="h5"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                    >
                      Documents:{" "}
                    </Typography>
                  )}
                  <Grid container spacing={1 } >

                  {documents?.length > 0 ? (
                    documents?.map((doc) => (
                      <Typography align="center" style={{cursor: 'pointer', color: '#30b0df'}}>
                        {doc.fileName}
                      <a href={doc.link} target="_blank" >
                        <img
                        style={{cursor: 'pointer', height: '150px', width: '100%', objectFit:'contain'}}
                        className="images"
                        src={
                          getFileExtension(doc.fileName) === "pdf"
                          ? "https://www.iowapork.org/wp-content/uploads/2020/03/adobe-acrobat-pdf-file-512.png"
                          : doc.link
                        }
                        alt=""
                        />
                      </a>
                        </Typography>
                    ))
                    ) 
                    : 
                    (
                      <Typography
                      variant="h5"
                      gutterBottom
                      style={{ marginLeft: "5px" }}
                      >
                      No documents yet
                    </Typography>
                  )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
        </Container>
      </PaperContentHolder>
    </Paper>
  );
}

export default ViewUserProfileAdmin;
