import { call, put, takeLatest } from "redux-saga/effects";

import API from "./ChannelsApis";
import * as ACTIONS from "./ChannelsAction";
import * as TYPES from "./ChannelsTypes";

export function* fetchAllChannelsData() {
  try {
    const response = yield call(API.apiFetchAllChannels);
    yield put(ACTIONS.allChannelsReceive(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* createChannel({ payload }) {
  try {
    const response = yield call(API.apiCreateChannel, payload);
    yield put(ACTIONS.createChannelCompleted(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* updateChannel({ channelId, payload }) {
  try {
    const response = yield call(API.apiUpdateChannel, channelId, payload);
    yield put(ACTIONS.updateChannelCompleted(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* deleteChannel({ channelId }) {
  try {
    yield call(API.apiDeleteChannel, channelId);
    yield put(ACTIONS.deleteChannelCompleted(channelId));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* ChannelsSaga() {
  yield takeLatest(TYPES.CHANNELS_REQUEST, fetchAllChannelsData);
  yield takeLatest(TYPES.CHANNEL_CREATE, createChannel);
  yield takeLatest(TYPES.CHANNEL_UPDATE, updateChannel);
  yield takeLatest(TYPES.CHANNEL_DELETE, deleteChannel);
}
