import { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Paper,
} from "@material-ui/core";

import PaperContentHolder from "../../components/PaperContent";
import DeleteChannelModal from "./DeleteChannelModal";
import EditChannelModal from "./EditChannelModal";

import { allChannelsRequest, createChannel, updateChannel, deleteChannel } from "../../store/channels/ChannelsAction";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SoundCloudChannelDefault from "../../images/soundcloud-logo.jpg";

function ChannelsSoundcloudPage() {
  const channels = useSelector((state) => state.channels);
  const dispatch = useDispatch();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedToDeleteId, setSelectedToDeleteId] = useState();

  const [showCreateUpdateModal, setShowCreateUpdateModal] = useState(false);
  const [selectedToUpdateId, setSelectedToUpdateId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    dispatch(allChannelsRequest());
  }, [dispatch]);

  if (!channels.isLoaded) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }

  const soundCloudChannels = channels.allChannels.filter((ch) => ch.type === "soundcloud");
  const selectedToDelete = soundCloudChannels.find((channel) => channel.id === selectedToDeleteId);
  const selectedToUpdate = soundCloudChannels.find((channel) => channel.id === selectedToUpdateId);

  return (
    <>
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <PaperContentHolder>
          <DeleteChannelModal
            show={showConfirmDeleteModal}
            onClose={() => setShowConfirmDeleteModal(false)}
            selectedChannel={selectedToDelete}
            isDeleting={channels.isDeleting}
            deleteAction={() => {
              dispatch(deleteChannel(selectedToDeleteId));
            }}
          />
          <EditChannelModal
            onClose={() => setShowCreateUpdateModal(false)}
            show={showCreateUpdateModal}
            selectedChannel={selectedToUpdate}
            isUpdating={channels.isUpdating}
            onSubmit={(values) => {
              setShowCreateUpdateModal(false);

              const payload = {
                type: "soundcloud",
                youTubeId: "",
                ...values,
              };
              if (selectedToUpdateId) {
                dispatch(updateChannel(selectedToUpdateId, payload));
              } else {
                dispatch(createChannel(payload));
              }
            }}
          />
          <Typography variant="h4">SoundCloud Account</Typography>
          <List>
            <Divider />
            {soundCloudChannels.map((channel, index) => (
              <Fragment key={index}>
                <ListItem
                  button
                  onClick={() => {
                    history.push(`/admin/channels/soundcloud/${channel.id}`);
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={SoundCloudChannelDefault}
                      alt="test"
                      style={{ width: "200px", height: "200px", marginRight: "30px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={channel.title}
                    secondary={`${channel.numberOfPlaylists} Playlists - ${channel.numberOfItems} Tracks`}
                    primaryTypographyProps={{ variant: "h5" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowCreateUpdateModal(true);
                        setSelectedToUpdateId(channel.id);
                      }}
                    >
                      <EditIcon fontSize="large" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowConfirmDeleteModal(true);
                        setSelectedToDeleteId(channel.id);
                      }}
                    >
                      <DeleteIcon fontSize="large" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Fragment>
            ))}
            <ListItem key={3}>
              <Container style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ width: "195px" }}
                  onClick={() => {
                    setSelectedToUpdateId(null);
                    setShowCreateUpdateModal(true);
                  }}
                >
                  Add Channel
                </Button>
              </Container>
            </ListItem>
          </List>
        </PaperContentHolder>
      </Paper>
    </>
  );
}

export default ChannelsSoundcloudPage;
