import * as TYPES from "./PlaylistsTypes";

export const allPlaylistsForChannelRequest = (channelId) => ({
  type: TYPES.PLAYLISTS_FOR_CHANNEL_REQUEST,
  channelId,
});

export const allPlaylistsForChannelReceive = (channelId, payload) => ({
  type: TYPES.PLAYLISTS_FOR_CHANNEL_RECEIVE,
  channelId,
  payload,
});

export const createPlaylist = (payload) => ({
  type: TYPES.PLAYLIST_CREATE,
  payload,
});

export const createPlaylistCompleted = (payload) => ({
  type: TYPES.PLAYLIST_CREATE_COMPLETED,
  payload,
});

export const updatePlaylist = (playlistId, payload) => ({
  type: TYPES.PLAYLIST_UPDATE,
  playlistId,
  payload,
});

export const updatePlaylistCompleted = (payload) => ({
  type: TYPES.PLAYLIST_UPDATE_COMPLETED,
  payload,
});

export const deletePlaylist = (playlistId) => ({
  type: TYPES.PLAYLIST_DELETE,
  playlistId,
});

export const deletePlaylistCompleted = (playlistId) => ({
  type: TYPES.PLAYLIST_DELETE_COMPLETED,
  playlistId,
});

export const playlistEnableRequest = (playlistId, value) => ({
  type: TYPES.PLAYLIST_ENABLE,
  playlistId,
  value,
});

export const playlistEnableReceive = (playlistId, value) => ({
  type: TYPES.PLAYLIST_ENABLE_COMPLETED,
  playlistId,
  value,
});
