import { Typography } from "@material-ui/core";
import styled from "styled-components";

const HeaderIntroContainer = styled.div`
  padding: 10px;
`;

function HomeIntro() {
  return (
    <HeaderIntroContainer>
      <Typography variant="h6" gutterBottom>
        Welcome Admin!
      </Typography>

      <Typography gutterBottom>Here can be nice widgets with statistics, or other things.</Typography>
    </HeaderIntroContainer>
  );
}

export default HomeIntro;
