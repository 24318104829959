import { call, put, takeLatest } from "redux-saga/effects";

import API from "./KnowledgeBaseApis";
import * as ACTIONS from "./KnowledgeBaseAction";
import * as TYPES from "./KnowledgeBaseTypes";

export function* fetchKnowledgeBase() {
  try {
    const response = yield call(API.apiFetchKnowledgeBase);
    yield put(ACTIONS.knowledgeBaseReceive(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* KnowledgeBaseSaga() {
  yield takeLatest(TYPES.KNOWLEDGE_BASE_REQUEST, fetchKnowledgeBase);
}
