import styled from "styled-components";
import { faGraduationCap, faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { faDeviantart } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ImageLandingMainImg from "../../images/landing-main-img.png";

import BlankFooter from "../../components/footer/BlankFooter";

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: rgb(247, 248, 250);
`;

const Container = styled.div`
  box-sizing: border-box;
  max-width: 375px;
  margin: 0 auto;
  padding: 60px 15px;
  @media (min-width: 768px) {
    max-width: 750px;
  }

  @media (min-width: 992px) {
    max-width: 970px;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
  }
`;

const MainBlock = styled.div`
  box-sizing: border-box;
  padding: 20px 20px 80px 20px;
  background-color: #ffffff;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 21px;
`;

const Header = styled.div`
  box-sizing: border-box;
  color: rgb(52, 52, 60);
  font-size: 30px;
  font-weight: 600;
  line-height: 42px;
  margin-bottom: 15px;
`;

const ImageTextFlexBlock = styled.div`
  box-sizing: border-box;
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 80px 0;
  }
`;

const TextContainer = styled.div`
  @media (min-width: 992px) {
    max-width: 400px;
  }
  @media (min-width: 1200px) {
    max-width: 550px;
  }
`;

const Paragraph = styled.div`
  box-sizing: border-box;
  color: rgb(96, 96, 96);
  margin-bottom: 10px;
`;

const ParagraphBigger = styled.div`
  box-sizing: border-box;
  color: #34343c;
  font-size: 16px;
  line-height: 22.4px;
  margin-bottom: 20px;
  @media (min-width: 992px) {
    margin-bottom: 40px;
  }
`;

const CardsGridBlock = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 327px;
  justify-content: center;
  @media (min-width: 768px) {
    column-gap: 15px;
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    justify-content: start;
    column-gap: 30px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  justify-items: center;
  margin: 30px 10px 0px 10px;
  text-align: center;
  @media (min-width: 992px) {
    margin-top: 40px 0 0 0;
    justify-items: start;
    text-align: left;
  }
`;

const CardLine = styled.div`
  box-sizing: border-box;
  display: none;
  width: 60px;
  height: 2px;
  margin-bottom: 50px;
  background-color: #0093c4;
  @media (min-width: 992px) {
    display: block;
  }
`;

const BetweenCardsLine = styled.div`
  box-sizing: border-box;
  display: block;
  width: 305px;
  height: 2px;
  margin: 40px 11px 15px 11px;
  background-color: #0093c4;
  @media (min-width: 992px) {
    display: none;
  }
`;

const Link = styled.a`
  box-sizing: border-box;
  color: #0093c4;
  font-size: 16px;
  line-height: 22.4px;
  text-decoration: none;
  &:hover {
    color: #3f51b5;
  }
`;

const IconElement = styled.div`
  width: 55px;
  height: 55px;
  background-color: #0093c4;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainImg = styled.img`
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  @media (min-width: 1200px) {
    width: 550px;
    height: 550px;
    max-width: 550px;
    max-height: 550px;
  }
`;

function LandingPage() {
  return (
    <Content>
      <Container>
        <MainBlock>
          <Header>Portal for sales agents of POS &#38; Merchant Services</Header>
          <ImageTextFlexBlock>
            <TextContainer>
              <Paragraph>
                CSI Works is an ISV company. We exist in complete harmony with sales agent in the payment processing
                industry. We have developed AgentStack.net exclusively for our independent sales channel. Indeed, this
                platform will notably strengthen our relationship and catapult your sales as much as you are willing to
                spend time on it. We will use this portal to train you, to offer you sales ideas, to listen to your
                requests and share ours thoughts. While using this portal, you will be provided with truly helpful
                tools, which will aid in generating even more sales.
              </Paragraph>
              <br />
              <Paragraph>
                We welcome you to gain experience with the portal's first version. Congratulations on the unique
                discovery of knowledge your competitors haven't detected yet.
              </Paragraph>
              <Paragraph>Hurry up to register while it's free and lock in your account for life.</Paragraph>
            </TextContainer>
            <MainImg src={ImageLandingMainImg} width="550" height="550" alt="shutterstock_1229580283(1)" />
          </ImageTextFlexBlock>
          <CardsGridBlock>
            <Wrapper>
              <IconElement>
                <FontAwesomeIcon
                  icon={faGraduationCap}
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                  }}
                />
              </IconElement>

              <Header>Learn payment solutions</Header>
              <CardLine />
              <ParagraphBigger>
                We have hundreds of technical ("How To") videos, pertaining to merchant industries, Also, our array of
                videos offer solutions to different verticals of Clover POS and CSI Works apps. Members will have access
                to all exclusive content.{" "}
              </ParagraphBigger>
              <Link href="https://www.agentstack.net/register">REGISTER NOW</Link>
            </Wrapper>
            <BetweenCardsLine />
            <Wrapper>
              <IconElement>
                <FontAwesomeIcon
                  icon={faBullhorn}
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                  }}
                />
              </IconElement>
              <Header>Obtain Marketing Material</Header>
              <CardLine />
              <ParagraphBigger>
                Industry-specific solution oriented marketing material. Ready to print and distribute to your leads.
                Just enter your contact information for conversion and begin distributing the flyers, postcards,
                brochures and more. All the contact information will lead back to you.
              </ParagraphBigger>
              <Link href="https://www.agentstack.net/register">REGISTER NOW</Link>
            </Wrapper>
            <BetweenCardsLine />
            <Wrapper>
              <IconElement>
                <FontAwesomeIcon
                  icon={faDeviantart}
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                  }}
                />
              </IconElement>
              <Header>Influence Future Development</Header>
              <CardLine />
              <ParagraphBigger>
                As we share our current development queue with members of Agentstack.net portal you can view the
                functionalities we plan to add and vote on its relevance as you see it. You will also have access to
                technical experts to let us know what you need to make your next big sale.
              </ParagraphBigger>
              <Link href="https://www.agentstack.net/register">REGISTER NOW</Link>
            </Wrapper>
          </CardsGridBlock>
        </MainBlock>
      </Container>
      <BlankFooter />
    </Content>
  );
}

export default LandingPage;
