import Auth from "../Auth";

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled ? false : true;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    const token = Auth.getToken();

    request.headers.common["Authorization"] = `Bearer ${token}`;
    request.headers.common["id"] = Auth.getUser().id;
  }
  return request;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
  }
  return response;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    if (error.response.status === 401 || error.response.status === 404) {
      Auth.signOut();
      window.location.href = "/home";
    }
  }
  return Promise.reject({ ...error });
};
