import { useParams } from "react-router-dom";
import ChannelSoundcloudPlaylistsPage from "./ChannelSoundcloudPlaylistsPage";

import ChannelYoutubePlaylistsPage from "./ChannelYoutubePlaylistsPage";

function ChannelPlaylistsPage() {
  const { type } = useParams();

  if (type === "soundcloud") {
    return <ChannelSoundcloudPlaylistsPage />;
  } else if (type === "youtube") {
    return <ChannelYoutubePlaylistsPage />;
  }

  return <>Invalid channel type</>;
}

export default ChannelPlaylistsPage;
