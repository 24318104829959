import { axiosInstance } from "../../network/api";
const handlerEnabled = true;

const apiSendToken = async (payload) => {
  return await axiosInstance.put(`/channels/youtube`, payload, { handlerEnabled });
};

export default {
  apiSendToken,
};
