import styled from "styled-components";

export const HeaderBlankContainer = styled.header`
  border: 2px solid lightgrey;
  border-left: transparent;
  border-right: transparent;
  border-top: transparent;

  display: grid;
  grid-template-columns: [Logo]250px auto [CsiLink]120px [Register]100px [LogIn]100px;
  align-items: center;

  @media (max-width: 600px) {
    grid-template-columns: [BurgerMenu]50px auto [Logo]250px 15px;
  }

  @media (max-width: 550px) {
    grid-template-columns: [BurgerMenu]50px auto [Logo]220px 15px;
  }

  @media (max-width: 475px) {
    grid-template-columns: [BurgerMenu]50px auto [Logo]220px 15px;
  }
`;

export const CsiLinkHolder = styled.div`
  grid-column: CsiLink;
  justify-self: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const RegisterHolder = styled.div`
  grid-column: Register;
  justify-self: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const LogInHolder = styled.div`
  grid-column: LogIn;
  justify-self: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const HeaderContainer = styled.header`
  border: 2px solid lightgrey;
  border-left: transparent;
  border-right: transparent;
  border-top: transparent;
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: [Logo]250px auto [UserName]150px [UserInfo]60px;
  align-items: center;

  @media (max-width: 600px) {
    grid-template-columns: [BurgerMenu]50px [Logo]250px auto [UserName]150px [UserInfo]60px;
  }

  @media (max-width: 550px) {
    grid-template-columns: [BurgerMenu]50px [Logo]220px auto [UserName]150px [UserInfo]60px;
  }

  @media (max-width: 475px) {
    grid-template-columns: [BurgerMenu]50px [Logo]220px auto [UserInfo]60px;
  }
`;

export const BurgerMenuHolder = styled.div`
  margin: 5px;
  grid-column: BurgerMenu;
  justify-self: center;

  @media (min-width: 601px) {
    display: none;
  }
`;

export const LogoHolder = styled.div`
  grid-column: Logo;
`;

export const PageContentContainer = styled.div`
  grid-row: ContentRow;
  display: flex;
  height: 100%;
  align-items: stretch;
`;

export const NavContainer = styled.div``;

export const MobileNavSpacer = styled.div`
  min-height: 69px;
`;

export const PrivacyTermsHolder = styled.div`
  position: fixed;
  bottom: 0;
  width: 240px;
`;

export const PrivacyTermsLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

export const MainContentContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
`;
