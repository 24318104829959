import { call, put } from "redux-saga/effects";
import API from "./UsersApis";
import * as ACTIONS from "./UsersAction";
import { takeLatest } from "redux-saga/effects";
import * as TYPES from "./UsersTypes";

export function* fetchAllUsersData() {
  try {
    const response = yield call(API.apiFetchAllUsers);
    yield put(ACTIONS.usersReceive(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* fetchAllUsersForReview() {
  try {
    const response = yield call(API.apiFetchAllUserForReview);
    yield put(ACTIONS.reviewsReceive(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* activateUser({ userId }) {
  try {
    yield call(API.apiActivateUser, userId);
    yield put(ACTIONS.usersActivateCompleted(userId));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* deactivateUser({ userId }) {
  try {
    yield call(API.apiDeactivateUser, userId);
    yield put(ACTIONS.usersDeactivateCompleted(userId));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* UsersSaga() {
  yield takeLatest(TYPES.USERS_REVIEW_REQUEST, fetchAllUsersForReview)
  yield takeLatest(TYPES.USERS_REQUEST, fetchAllUsersData);
  yield takeLatest(TYPES.USERS_ACTIVATE, activateUser);
  yield takeLatest(TYPES.USERS_DEACTIVATE, deactivateUser);
}
