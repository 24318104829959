import { axiosInstance } from "../../network/api";
const handlerEnabled = true;

const apiFetchPlaylistById = async (channelId, playlistId) => {
  return await axiosInstance.get(`/channels/${channelId}/playlists/${playlistId}`, { handlerEnabled });
};

const apiAddItemToPlaylist = async (playlistId, payload) => {
  return await axiosInstance.put(`/playlists/${playlistId}/items`, payload, { handlerEnabled });
};

const apiDeleteItemFromPlaylist = async (playlistId, itemId) => {
  return await axiosInstance.delete(`/playlists/${playlistId}/items/${itemId}`, { handlerEnabled });
};

export default {
  apiFetchPlaylistById,
  apiAddItemToPlaylist,
  apiDeleteItemFromPlaylist
};
