import styled from "styled-components";

export const ExclusiveVideosContainer = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: [Title]auto [Prev]45px [Next]45px [End];
  grid-template-rows: [Header]40px [Content]auto;
  align-items: center;
`;

export const ExclusiveVideosTitleHolder = styled.div`
  grid-column: Title;
  grid-row: Header;
`;

export const ExclusiveVideosPrevHolder = styled.div`
  grid-column: Prev;
  grid-row: Header;
`;

export const ExclusiveVideosNextHolder = styled.div`
  grid-column: Next;
  grid-row: Header;
`;

export const ExclusiveVideosCarouselHolder = styled.div`
  grid-column: Title / End;
  grid-row: Content;

  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

export const ExclusiveVideosCarouselIframeHolder = styled.div`
  width: 100%;

  iframe {
    width: 100%;
    height: 250px;
  }
`;

export const ExclusiveVideosCarouselSubscribeHolder = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  min-height: 25px;
`;

export const ExclusiveVideosCarouselDescription = styled.p`
  white-space: pre-wrap;
`;
