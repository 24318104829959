import * as TYPES from "./PlaylistsTypes";

const INITIAL_STATE = {
  isLoaded: false,
  isChangingEnabledValue: false,
  isUpdating: false,
  isDeleting: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.PLAYLISTS_FOR_CHANNEL_REQUEST:
      return {
        ...state,
        ...action.payload,
        isLoaded: false,
        isUpdating: false,
      };
    case TYPES.PLAYLISTS_FOR_CHANNEL_RECEIVE:
      return {
        ...state,
        loadedChannelId: action.channelId,
        channelPlaylists: action.payload,
        isLoaded: true,
        isUpdating: false,
      };
    case TYPES.PLAYLIST_ENABLE:
      return {
        ...state,
        isChangingEnabledValue: true,
      };
    case TYPES.PLAYLIST_ENABLE_COMPLETED:
      const changedPlaylist = state.channelPlaylists.find((playlist) => playlist.id === action.playlistId);
      changedPlaylist.enabled = action.value;
      return {
        ...state,
        isChangingEnabledValue: false,
      };
    case TYPES.PLAYLIST_CREATE:
      return {
        ...state,
        isUpdating: true,
      };
    case TYPES.PLAYLIST_CREATE_COMPLETED:
      return {
        ...state,
        channelPlaylists: [...state.channelPlaylists, action.payload],
        isUpdating: false,
      };
    case TYPES.PLAYLIST_UPDATE:
      return {
        ...state,
        isUpdating: true,
      };
    case TYPES.PLAYLIST_UPDATE_COMPLETED:
      const index = state.channelPlaylists.findIndex((pl) => pl.id === action.payload.id);
      state.channelPlaylists[index] = action.payload;
      return {
        ...state,
        isUpdating: false,
      };
    case TYPES.PLAYLIST_DELETE:
      return {
        ...state,
        isDeleting: true,
      };
    case TYPES.PLAYLIST_DELETE_COMPLETED:
      return {
        ...state,
        channelPlaylists: state.channelPlaylists.filter((pl) => pl.id !== action.playlistId),
        isDeleting: false,
      };
    default:
      return state;
  }
};
