import * as TYPES from "./ChannelByIdTypes";

const INITIAL_STATE = {
  channel: null,
  channelId: null,
  isLoaded: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.CHANNEL_BY_ID_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case TYPES.CHANNEL_BY_ID_RECEIVE:
      return {
        channel: action.payload,
        channelId: action.payload.id,
        isLoaded: true,
      };
    default:
      return state;
  }
};
