import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, IconButton, LinearProgress } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons/";
import Carousel from "react-material-ui-carousel";

import { exclusiveVideosRequest } from "../../store/exclusive-videos/ExclusiveVideosAction";

import {
  ExclusiveVideosContainer,
  ExclusiveVideosTitleHolder,
  ExclusiveVideosPrevHolder,
  ExclusiveVideosNextHolder,
  ExclusiveVideosCarouselHolder,
  ExclusiveVideosCarouselIframeHolder,
  ExclusiveVideosCarouselSubscribeHolder,
  ExclusiveVideosCarouselDescription,
} from "./style";

function ExclusiveVideos() {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isBusy, setIsBusy] = useState(false);

  const exclusiveVideos = useSelector((state) => state.exclusiveVideos);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(exclusiveVideosRequest());
  }, [dispatch]);

  const items = exclusiveVideos.channels;
  const onlyOne = items && items.length === 1;

  function prevSlide() {
    setIsBusy(true);
    if (slideIndex === 0) {
      setSlideIndex(items.length - 1);
    } else {
      setSlideIndex(slideIndex - 1);
    }
  }

  function nextSlide() {
    setIsBusy(true);
    if (slideIndex === items.length - 1) {
      setSlideIndex(0);
    } else {
      setSlideIndex(slideIndex + 1);
    }
  }

  return (
    <>
      <LinearProgress style={{ visibility: exclusiveVideos.isLoaded ? "hidden" : "visible" }} />
      <ExclusiveVideosContainer>
        <ExclusiveVideosTitleHolder>
          <Typography variant="h6" gutterBottom>
            Exclusive Video Content
          </Typography>
        </ExclusiveVideosTitleHolder>
        <ExclusiveVideosPrevHolder>
          <IconButton onClick={prevSlide} disabled={onlyOne || isBusy || !exclusiveVideos.isLoaded}>
            <ArrowBackIos fontSize="small" />
          </IconButton>
        </ExclusiveVideosPrevHolder>
        <ExclusiveVideosNextHolder>
          <IconButton onClick={nextSlide} disabled={onlyOne || isBusy || !exclusiveVideos.isLoaded}>
            <ArrowForwardIos fontSize="small" />
          </IconButton>
        </ExclusiveVideosNextHolder>
        <ExclusiveVideosCarouselHolder>
          <Carousel
            index={slideIndex}
            autoPlay={false}
            navButtonsAlwaysInvisible={true}
            indicators={false}
            onChange={(index) => {
              setIsBusy(false);
              setSlideIndex(index);
            }}
          >
            {items.map((item, i) => (
              <CarouselItem key={i} item={item} />
            ))}
          </Carousel>
        </ExclusiveVideosCarouselHolder>
      </ExclusiveVideosContainer>
    </>
  );
}

function CarouselItem({ item }) {
  const hasWindow = typeof window !== "undefined";

  useEffect(() => {
    if (hasWindow) {
      window.gapi.ytsubscribe.go();

      // clean up code to delete Google YouTube subscribe button HTML code
      // it is quite fragile, and can be an issue if other 3rd party libs
      // will create HTML elements at body root level
      return () => {
        const iframe = document.querySelector("body > iframe");
        if (iframe) {
          iframe.remove();
        }
        const div = document.querySelector("body > div:not([role], #root)");
        if (div) {
          div.remove();
        }
        const ins = document.querySelector("body > ins");
        if (ins) {
          ins.remove();
        }
      };
    }
  });

  const urlParts = item.videoEmbedUrl.split("=");
  const videoId = urlParts[urlParts.length - 1];

  return (
    <>
      <ExclusiveVideosCarouselIframeHolder>
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </ExclusiveVideosCarouselIframeHolder>
      <Typography gutterBottom>{item.title}</Typography>
      <ExclusiveVideosCarouselDescription>{item.description}</ExclusiveVideosCarouselDescription>
      <ExclusiveVideosCarouselSubscribeHolder>
        <div
          className="g-ytsubscribe"
          data-channelid={item.channelName}
          data-layout="default"
          data-count="hidden"
        ></div>
      </ExclusiveVideosCarouselSubscribeHolder>
    </>
  );
}

export default ExclusiveVideos;
