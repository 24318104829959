import * as TYPES from "./ExclusiveVideosTypes";

const INITIAL_STATE = {
  isLoaded: false,
  channels: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.EXCLUSIVE_VIDEOS_REQUEST:
      return {
        ...state,
      };
    case TYPES.EXCLUSIVE_VIDEOS_RECEIVE:
      return {
        ...state,
        channels: action.payload,
        isLoaded: true,
      };
    default:
      return state;
  }
};
