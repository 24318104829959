import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link, Avatar } from "@material-ui/core";
import styled from "styled-components";
import EditIcon from '@material-ui/icons/Edit';

import Auth from "../Auth";
import { profileRequest, profileCleanOnLogout } from "../store/profile/ProfileAction";

import DefaultAvatar from "../images/default-avatar.png";

export const UserInfoContainer = styled.div`
  grid-column: UserInfo;
`;

export const UserName = styled.div`
  display: block;
  grid-column: UserName;
  text-align: center;

  @media (max-width: 475px) {
    display: none;
  }
`;

export const UserNameMobile = styled.div`
  height: 69px;
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;

  @media (max-width: 475px) {
    display: flex;
  }
`;

export const UserInfoMobile = () => {
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileRequest(Auth.getUser().id));
  }, [dispatch]);

  if (!profile.isLoaded) {
    return <></>;
  }

  return (
    <UserNameMobile>
      <div>
        <Link component={RouterLink} to="/profile">
          <Typography>
            {profile.firstName} {profile.lastName}
          </Typography>
        </Link>
        <Link
          component={RouterLink}
          to="/login"
          onClick={() => {
            Auth.signOut();
            dispatch(profileCleanOnLogout());
          }}
        >
          <Typography variant="caption">Log Out</Typography> 
        </Link>
      </div>
    </UserNameMobile>
  );
};

function UserInfo() {
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileRequest(Auth.getUser().id));
  }, [dispatch]);

  if (!profile.isLoaded) {
    return <></>;
  }

  return (
    <>
      <UserName>
        <Link component={RouterLink} to="/profile">

          <Typography>
            {profile.firstName} {profile.lastName} 
           
          </Typography>
        </Link>
        <Link
          component={RouterLink}
          to="/login"
          onClick={() => {
            Auth.signOut();
            dispatch(profileCleanOnLogout());
          }}
        >
          <Typography variant="caption">Log Out</Typography>
        </Link>
      </UserName>
      <UserInfoContainer>
        <Link component={RouterLink} to="/profile">
        <Avatar src={DefaultAvatar} alt={"default user"} />
        </Link>
      </UserInfoContainer>
    </>
  );
}

export default UserInfo;
