import { call, put } from "redux-saga/effects";
import API from "./YoutubeOauthApis";
import * as ACTIONS from "./YoutubeOauthAction";
import { takeLatest } from "redux-saga/effects";
import * as TYPES from "./YoutubeOauthTypes";

export function* sendTokenToServer({ payload }) {
  try {
    const response = yield call(API.apiSendToken, payload);
    yield put(ACTIONS.oauthReceive(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* YoutubeOauthSaga() {
  yield takeLatest(TYPES.YOUTUBE_OAUTH_REQUEST, sendTokenToServer);
}
