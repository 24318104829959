import { call, put, takeLatest } from "redux-saga/effects";

import API from "./ChannelByIdApis";
import * as ACTIONS from "./ChannelByIdAction";
import * as TYPES from "./ChannelByIdTypes";

export function* fetchChannelByIdData({ channelId }) {
  try {
    const response = yield call(API.apiFetchChannelById, channelId);
    yield put(ACTIONS.channelByIdReceive(channelId, response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* ChannelByIdSaga() {
  yield takeLatest(TYPES.CHANNEL_BY_ID_REQUEST, fetchChannelByIdData);
}
