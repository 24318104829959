import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Container, Grid, LinearProgress, Link, Typography } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import { apiRegisterNewAgent } from "../../network/authApis";

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().min(14, "Incomplete phone number").required("Required"),
  password: Yup.string().min(6, "Min 6 characters long").required("Required"),
});

function RegistrationPage() {
  const [sendRegistration, setSendRegistration] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          password: "",
        }}
        validationSchema={RegistrationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          apiRegisterNewAgent(values)
            .then((data) => {
              setSubmitting(false);
              setSendRegistration(true);
            })
            .catch((err) => {
              setSubmitting(false);
              setErrors({ email: err.response.data });
            });
        }}
      >
        {({ setFieldValue, setFieldTouched, submitForm, isSubmitting, errors, touched }) => (
          <div>
            <LinearProgress style={{ visibility: isSubmitting ? "visible" : "hidden" }} />
            <Container style={{ marginTop: "20px" }} maxWidth="sm">
              <Form>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography component="div">
                      <Box fontSize="h6.fontSize" style={{ color: "#0093c4" }}>
                        Start your free account today
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="firstName"
                      label="First name"
                      placeholder="John"
                      variant="outlined"
                      disabled={isSubmitting || sendRegistration}
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="lastName"
                      label="Last name"
                      placeholder="Smith"
                      variant="outlined"
                      disabled={isSubmitting || sendRegistration}
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label="Email"
                      placeholder="example@email.com"
                      variant="outlined"
                      disabled={isSubmitting || sendRegistration}
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MuiPhoneNumber
                      id="phone"
                      name="phone"
                      label="Phone number"
                      variant="outlined"
                      disableCountryCode={true}
                      disableDropdown={true}
                      onlyCountries={["us", "ca"]}
                      defaultCountry="us"
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                      onChange={(e) => {
                        setFieldTouched("phone");
                        setFieldValue("phone", e);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("phone");
                      }}
                      disabled={isSubmitting || sendRegistration}
                      error={touched.phone && Boolean(errors.phone)}
                      helperText={touched.phone && errors.phone}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="password"
                      label="Password"
                      type="password"
                      variant="outlined"
                      disabled={isSubmitting || sendRegistration}
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography color="primary" style={{ visibility: sendRegistration ? "visible" : "hidden" }}>
                      You have successfully created an Agent profile on AgentStack. <br /> In order to activate it
                      please confirm your email.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={isSubmitting || sendRegistration}
                      onClick={submitForm}
                      style={{ width: "195px" }}
                    >
                      Register
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Link component={RouterLink} to="/login">
                      <Typography>Back to Login</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          </div>
        )}
      </Formik>
    </>
  );
}

export default RegistrationPage;
