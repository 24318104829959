import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Container, Grid, Link, LinearProgress, Typography } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import { apiResetPassword } from "../../network/authApis";

const RestorePasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

function RestorePasswordPage() {
  const [sendRestore, setSendRestore] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={RestorePasswordSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          apiResetPassword(values)
            .then(() => {
              setSubmitting(false);
              setSendRestore(true);
            })
            .catch((err) => {
              setSubmitting(false);
              setErrors({ email: err.response.data });
            });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <div>
            <LinearProgress style={{ visibility: isSubmitting ? "visible" : "hidden" }} />
            <Container style={{ marginTop: "20px" }} maxWidth="xs">
              <Form>
                <Grid container justifyContent="center" direction="column" spacing={2}>
                  <Grid item style={{ textAlign: "center" }}>
                    <Typography component="div">
                      <Box fontSize="1.1rem" style={{ color: "#0093c4" }}>
                        Please enter your email to restore password
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label="Email"
                      variant="outlined"
                      disabled={isSubmitting || sendRestore}
                      required
                      fullWidth
                      style={{ minHeight: "4rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography color="primary" style={{ visibility: sendRestore ? "visible" : "hidden" }}>
                      Please wait for email with new password
                    </Typography>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={isSubmitting || sendRestore}
                      onClick={submitForm}
                    >
                      Restore Password
                    </Button>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Link component={RouterLink} to="/login">
                      <Typography>Back to Login</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          </div>
        )}
      </Formik>
    </>
  );
}

export default RestorePasswordPage;
