import { useState } from "react";
import { Link as RouterLink, Redirect, useLocation } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import OtpInput from "react-otp-input";
import * as Yup from "yup";
import "../../App.css";
import Auth from "../../Auth";
import { apiLogin } from "../../network/authApis";
import { useEffect } from "react";
import axios from "axios";
import { axiosInstance } from "../../network/api";
import { BASE_URL } from "../../config";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LoginSchema = Yup.object().shape({
  password: Yup.string().min(6, "Min 6 characters long").required("Required"),
});

function LoginPage() {
  const [redirectAfterLogin, setRedirectAfterLogin] = useState(false);
  const [otp, setOtp] = useState("");
  const [username, setUsername] = useState("");
  const [admin, setAdmin] = useState(false);
  const [qrcode, setQrcode] = useState();
  const query = useQuery();

  function handleChange(otp) {
    setOtp(otp);
  }

  const checkIfAdmin = async () => {
    if (username.includes(("@" && ".net") || ".com" || ".ru")) {
      const res = await axios.get(`${BASE_URL}/login/isAdmin/${username}`);
      setAdmin(res.data);
      const image = await axios.get(`${BASE_URL}/barcode/${username}`, {
        responseType: "arraybuffer",
      });
      let base64ImageString = Buffer.from(image.data, "binary").toString(
        "base64"
      );
      setQrcode(base64ImageString);
    } else {
      setAdmin(false);
    }
  };

  useEffect(() => {
    checkIfAdmin();
  }, [username]);

  if (redirectAfterLogin || Auth.isLoggedIn()) {
    if (Auth.getUser().role === "admin") {
      return <Redirect to="/admin" />;
    } else {
      return <Redirect to="/home" />;
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          Object.assign(values, { username: username, otp: otp });
          apiLogin(values)
            .then((data) => {
              Auth.setAuth(data.token, data.validUntil, data.user);
              setRedirectAfterLogin(true);
            })
            .catch((err) => {
              setSubmitting(false);
            });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <div>
            <LinearProgress
              style={{ visibility: isSubmitting ? "visible" : "hidden" }}
            />
            <Container style={{ marginTop: "20px" }} maxWidth="xs">
              <Form>
                <Grid container justifyContent="center" direction="column" spacing={2}>
                  {query.has("confirmed-email") ? (
                    <Grid item style={{ textAlign: "center" }}>
                      <Typography color="primary">
                        Email was confirmed
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item>
                    <Field
                      component={TextField}
                      name="username"
                      type="email"
                      label="Username"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>

                  <Grid item>
                    <Field
                      component={TextField}
                      type="password"
                      label="Password"
                      name="password"
                      variant="outlined"
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  {admin && (
                    <>
                      <Grid container justifyContent="center">
                        <OtpInput
                          inputStyle="otp_element"
                          value={otp}
                          onChange={handleChange}
                          numInputs={6}
                          separator={<span>-</span>}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={isSubmitting}
                      style={{ width: "195px" }}
                    >
                      Log In
                    </Button>
                  </Grid>

                  <Grid item style={{ textAlign: "center" }}>
                    <Link component={RouterLink} to="/login/restore">
                      <Typography>Forgot Password?</Typography>
                    </Link>
                  </Grid>

                  <Grid item style={{ textAlign: "center" }}>
                    <Link component={RouterLink} to="/register">
                      <Typography>Don't have an account? Register</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          </div>
        )}
      </Formik>
    </>
  );
}

export default LoginPage;
