import { axiosInstance } from "../../network/api";
const handlerEnabled = true;

const apiFetchExclusiveVideos = async () => {
  return await axiosInstance.get(`/channels/featured`, { handlerEnabled });
};

export default {
  apiFetchExclusiveVideos,
};
