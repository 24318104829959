import * as TYPES from "./ExclusiveVideosTypes";

export const exclusiveVideosRequest = () => ({
  type: TYPES.EXCLUSIVE_VIDEOS_REQUEST,
});

export const exclusiveVideosReceive = (payload) => ({
  type: TYPES.EXCLUSIVE_VIDEOS_RECEIVE,
  payload,
});
