import { Container, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";

const Brand = () => {
  const { brandName } = useParams();
  return (
    <>
      <Grid>
        <Typography variant="h4">Buy Equipment In Bulk</Typography>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            When you source hardware equipment and accessories from AgentStack
            you earn income and ensure that your merchants get 100% support and
            training on equipment you sell it. Just register the hardware you
            onboard.
          </Typography>
          <TextField
            name="search"
            label="Search"
            style={{ width: "300px", margin: "0px 20px" }}
            size="small"
            variant="outlined"
            onChange={(e) => {}}
          />
        </div>
      </Grid>

      <Container maxWidth="lg">
      
        <Grid
          container
          spacing={1}
          style={{ }}
          align="center"
          justifyContent="center"
        >
          <Grid container alignItems="center">
          <hr />
              <Grid item >
            <Typography variant="subtitle1" align="center" style={{ fontWeight: "bold" }}>
              Home &nbsp; {" > "} &nbsp; By Scale Brand &nbsp; {" > "} &nbsp;{" "}
              {brandName}
            </Typography>
              </Grid>
            <hr />
          </Grid>
          <Grid item>
            <div className="card">
              <Typography>Buy Scale Brand</Typography>
            </div>
          </Grid>
          <Grid item>
            <div className="card">
              <Typography>Buy Scale Price</Typography>
            </div>
          </Grid>
          <Grid item>
            <div className="card">
              <Typography>Buy Scale Capacity</Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1} align="center" justify="center">
          <Grid item>
            <div className="card">
              <Typography>Buy Scale Capacity</Typography>
            </div>
          </Grid>
          <Grid item>
            <div className="card">
              <Typography>Buy Scale Capacity</Typography>
            </div>
          </Grid>
          <Grid item>
            <div className="card">
              <Typography>Buy Industry's Choice</Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Brand;
