import * as TYPES from "./YoutubeOauthTypes";

export const oauthRequest = (payload) => ({
  type: TYPES.YOUTUBE_OAUTH_REQUEST,
  payload,
});

export const oauthReceive = (payload) => ({
  type: TYPES.YOUTUBE_OAUTH_RECEIVE,
  payload,
});
