import { call, put } from "redux-saga/effects";
import API from "./ExclusiveVideosApis";
import * as ACTIONS from "./ExclusiveVideosAction";
import { takeLatest } from "redux-saga/effects";
import * as TYPES from "./ExclusiveVideosTypes";

export function* fetchExclusiveVideosData() {
  try {
    const response = yield call(API.apiFetchExclusiveVideos);
    yield put(ACTIONS.exclusiveVideosReceive(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* ExclusiveVideosSaga() {
  yield takeLatest(TYPES.EXCLUSIVE_VIDEOS_REQUEST, fetchExclusiveVideosData);
}
