import { Card, Grid } from "@material-ui/core";

import HomeAdminIntro from "./HomeAdminIntro";

function HomeAdminPage() {
  return (
    <>
      <Grid container justify="space-between" spacing={2}>
        <Grid item xl={6} lg={8} md={9} sm={12}>
          <Card>
            <HomeAdminIntro />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default HomeAdminPage;
