import * as TYPES from "./ProfileTypes";

export const profileRequest = (profileId) => ({
  type: TYPES.PROFILE_REQUEST,
  profileId,
});

export const profileReceive = (payload) => ({
  type: TYPES.PROFILE_RECEIVE,
  payload,
});

export const profileUpdate = (profileId, payload) => ({
  type: TYPES.PROFILE_UPDATE,
  profileId,
  payload,
});

export const profileChangePassword = (profileId, payload) => ({
  type: TYPES.PROFILE_CHANGING_PASSWORD,
  profileId,
  payload,
});

export const profileChangePasswordError = (error) => ({
  type: TYPES.PROFILE_CHANGING_PASSWORD_ERROR,
  error,
});

export const profileChangedPassword = () => ({
  type: TYPES.PROFILE_CHANGED_PASSWORD,
});

export const profileCleanOnLogout = () => ({
  type: TYPES.PROFILE_CLEAN_ON_LOGOUT,
});
