import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

const PlaylistItemSchema = Yup.object().shape({
  playlistItemTitle: Yup.string().max(100, "Title cannot exceed 100 characters").required("Required"),
  playlistItemDescription: Yup.string().max(5000, "Description cannot exceed 5000 characters").required("Required"),
  playlistItemLink: Yup.string().url("Link should be valid URL").required("Required"),
});

export default function EditPlaylistItemModal({ onClose, show, selectedPlaylistItem, isUpdating, onSubmit }) {
  return (
    <Dialog
      fullWidth={true}
      open={show || isUpdating}
      disableBackdropClick={isUpdating}
      disableEscapeKeyDown={isUpdating}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          playlistItemTitle: (selectedPlaylistItem && selectedPlaylistItem.title) || "",
          playlistItemDescription: (selectedPlaylistItem && selectedPlaylistItem.description) || "",
          playlistItemLink: (selectedPlaylistItem && selectedPlaylistItem.link) || "",
        }}
        validationSchema={PlaylistItemSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm }) => (
          <>
            <Form>
              <DialogTitle id="alert-dialog-title">
                Create new playlist item
                {isUpdating ? <LinearProgress /> : <LinearProgress style={{ visibility: "hidden" }} />}
              </DialogTitle>
              <DialogContent>
                <Field
                  component={TextField}
                  name="playlistItemTitle"
                  label="Title"
                  placeholder="Playlist name"
                  variant="outlined"
                  disabled={isUpdating}
                  required
                  fullWidth
                  style={{ minHeight: "5rem", width: "100%" }}
                />
                <Field
                  component={TextField}
                  name="playlistItemDescription"
                  label="Description"
                  placeholder="Playlist description"
                  multiline
                  rows={10}
                  variant="outlined"
                  disabled={isUpdating}
                  required
                  fullWidth
                  style={{ minHeight: "16rem", width: "100%" }}
                />
                <Field
                  component={TextField}
                  name="playlistItemLink"
                  label="Link"
                  placeholder="link to track"
                  variant="outlined"
                  disabled={isUpdating}
                  required
                  fullWidth
                  style={{ minHeight: "5rem", width: "100%" }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} disabled={isUpdating} color="primary">
                  Cancel
                </Button>
                <Button onClick={submitForm} disabled={isUpdating} color="primary" autoFocus>
                  Save
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
