import * as TYPES from "./PlaylistByIdTypes";

export const playlistByIdRequest = (channelId, playlistId) => ({
  type: TYPES.PLAYLIST_BY_ID_REQUEST,
  channelId,
  playlistId,
});

export const playlistByIdReceive = (playlistId, payload) => ({
  type: TYPES.PLAYLIST_BY_ID_RECEIVE,
  playlistId,
  payload,
});

export const addItemToPlaylist = (playlistId, payload) => ({
  type: TYPES.PLAYLIST_ITEM_ADD,
  playlistId,
  payload,
});

export const addItemToPlaylistCompleted = (playlistId, payload) => ({
  type: TYPES.PLAYLIST_ITEM_ADD_COMPLETED,
  playlistId,
  payload,
});

export const deleteItemFromPlaylist = (playlistId, playlistItemId) => ({
  type: TYPES.PLAYLIST_ITEM_DELETE,
  playlistId,
  playlistItemId,
});

export const deleteItemFromPlaylistCompleted = (playlistId, playlistItemId) => ({
  type: TYPES.PLAYLIST_ITEM_DELETE_COMPLETED,
  playlistId,
  playlistItemId,
});
