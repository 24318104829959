import { combineReducers } from "redux";

import profile from "./profile/ProfileReducer";
import users from "./users/UsersReducer";
import youtubeOauth from "./youtube-oauth/YoutubeOauthReducer";
import channels from "./channels/ChannelsReducer";
import channelById from "./channel-by-id/ChannelByIdReducer";
import playlists from "./playlists/PlaylistsReducer";
import playlistById from "./playlist-by-id/PlaylistByIdReducer";
import knowledgeBase from "./knowledge-base/KnowledgeBaseReducer";
import exclusiveVideos from "./exclusive-videos/ExclusiveVideosReducer";

export default combineReducers({
  profile,
  users,
  youtubeOauth,
  channels,
  channelById,
  playlists,
  playlistById,
  knowledgeBase,
  exclusiveVideos,
});
