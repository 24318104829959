import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LinearProgress,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  ListItemText,
  Paper,
  Checkbox,
  Toolbar,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { channelByIdRequest } from "../../store/channel-by-id/ChannelByIdAction";
import { allPlaylistsForChannelRequest, playlistEnableRequest } from "../../store/playlists/PlaylistsAction";

import PaperContentHolder from "../../components/PaperContent";

function ChannelYoutubePlaylistsPage() {
  const { id } = useParams();

  const channels = useSelector((state) => state.channels);
  const playlists = useSelector((state) => state.playlists);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(channelByIdRequest(id));
    dispatch(allPlaylistsForChannelRequest(id));
  }, [dispatch, id]);

  if (!channels.isLoaded || !playlists.isLoaded || playlists.loadedChannelId != id) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }

  const channel = channels.allChannels.find((ch) => ch.id == id);

  if (!channel) {
    return <>Invalid channel id is used</>;
  }

  return (
    <>
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <PaperContentHolder>
          <Typography variant="h4">SoundCloud Account</Typography>
          <Typography variant="h5">Channel Playlists</Typography>

          <Toolbar>
            <Typography variant="h6" id="tableTitle" component="div" style={{ flex: "1 1" }}>
              {channel.title}
            </Typography>
          </Toolbar>
          <LinearProgress style={{ visibility: playlists.isUpdating ? "visible" : "hidden" }} />
          <TableContainer component={Paper} style={{ maxHeight: "725px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow></TableRow>
                <TableRow>
                  <TableCell>Playlist</TableCell>
                  <TableCell width="150px">Visibility</TableCell>
                  <TableCell align="right" width="120px">Video count</TableCell>
                  <TableCell align="center" width="150px">Show to Agents</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {playlists.channelPlaylists.length === 0 ? (
                  <TableRow>
                    <TableCell>No Channels</TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
                {playlists.channelPlaylists.map((playlist, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" style={{ display: "flex", alignItems: "center" }}>
                      <img src={playlist.youTubeCoverUrl} alt="test" style={{ height: "200px", marginRight: "30px" }} />
                      <ListItemText primary={playlist.title} secondary={playlist.description} />
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {playlist.youtubeVisibility === "public" ? (
                          <>
                            <VisibilityIcon style={{ color: green[500], marginRight: "15px" }} />
                            <Typography>Public</Typography>
                          </>
                        ) : (
                          <>
                            <VisibilityOffIcon style={{ marginRight: "15px" }} />
                            <Typography>Unlisted</Typography>
                          </>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="right">{playlist.numberOfItems}</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        name="showToAgent"
                        color="primary"
                        checked={playlist.enabled}
                        disabled={playlists.isChangingEnabledValue}
                        onChange={(e) => {
                          dispatch(playlistEnableRequest(playlist.id, e.target.checked));
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PaperContentHolder>
      </Paper>
    </>
  );
}

export default ChannelYoutubePlaylistsPage;
