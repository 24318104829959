import styled from "styled-components";

import BlankFooter from "../../components/footer/BlankFooter";

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: rgb(247, 248, 250);
`;

const Container = styled.div`
  box-sizing: border-box;
  max-width: 375px;
  margin: 0 auto;
  padding: 60px 15px;
  @media (min-width: 768px) {
    max-width: 750px;
  }

  @media (min-width: 992px) {
    max-width: 970px;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
  }
`;

const Strong = styled.span`
  font-weight: 700;
`;

const MainBlock = styled.div`
  box-sizing: border-box;
  padding: 20px;
  background-color: #ffffff;
  color: #606060;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
`;

const Paragraph = styled.div`
  box-sizing: border-box;
  color: rgb(52, 52, 60);
  margin-bottom: 10px;
`;

const CenteredParagraph = styled.div`
  box-sizing: border-box;
  margin-bottom: 10px;
  text-align: center;
`;

const Link = styled.a`
  box-sizing: border-box;
  font-weight: 400;
  color: #3f51b5;
  text-decoration: none;
  margin-bottom: 60px;
  &:hover {
    text-decoration: underline;
  }
`;

const List = styled.div`
  padding-left: 40px;
`;

function PrivacyPolicyPage() {
  return (
    <Content>
      <Container>
        <MainBlock>
          <Paragraph>This Privacy Policy was last modified on April 29, 2021.</Paragraph>
          <CenteredParagraph>
            <Strong>Agentstack.Net by CSI Works</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Privacy Policy</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            Sell Seven, Inc. (Doing Business As CSI Works) values the privacy of individuals and is committed to
            protecting and respecting the information of visitors to the website and individuals or entities that
            register to use the products and services through the sites:{" "}
            <Link href="https://agentstack.net">www.agentstack.net</Link>
          </CenteredParagraph>
          <div style={{ height: "31px" }}></div>
          <CenteredParagraph>
            <Strong>Consent</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            This Privacy Policy (‘Privacy Policy’) describes how Sell Seven, Inc. (“CSI Works,” “we,” or “us”) collects
            and uses information of visitors to the website and individuals or entities that register to use the
            products and services (“visitors,” “users,” or “you”) through{" "}
            <Link href="https://agentstack.net"> www.agentstack.net</Link> (the “Site”) and any of its subdomains,
            mobile applications, and related services (collectively, the “Services”).
          </CenteredParagraph>
          <CenteredParagraph>
            By accessing or using any of our Services, you accept and signify your consent to the practices described in
            this Privacy Policy. If you do not agree with the policies and practices contained in this Privacy Policy,
            your choice is not to use this Site or the Services in connection with this Site.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>How we use your information/data</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            Your personal information/data may be processed and used for the following purposes:
          </CenteredParagraph>
          <List>
            <Strong>a. Compliance:</Strong> to fulfil obligations provided by law and regulations.
            <br /> <Strong>b. Account Registration:</Strong> to create an account with CSI Works. <br />{" "}
            <Strong>c. Customer support services:</Strong> to provide you with a response to your questions(e.g.,
            concerning apps and services). <br /> <Strong>d. Fulfilling Services:</Strong> to provide the services
            pursuant to our agreement with you.
            <br /> <Strong>e. Marketing:</Strong> to send marketing communications, e.g., news relating to our business,
            events, new products and services, and updates/news on existing products and services or, to conduct product
            market research.
          </List>
          <CenteredParagraph>
            <Strong>The personal information we collect</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            We collect personal information about individuals from various sources described below. Where applicable, we
            indicate whether and why individuals must provide us with personal data, as well as consequences of failing
            to do so.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>A. Information that we collect from merchant's about their customers</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            Our merchants may provide us with information about their customers. This information may include uploaded
            email addresses, phone numbers, and purchase history, for instance when we manage promotions or marketing
            communications or perform other services on behalf of a merchant.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>B. Information that we collect about Merchants and their personnel</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            We may collect personal information directly from merchants (including prospective customers) about
            themselves and their personnel. We may collect information from these parties in a variety of contexts, such
            as when completing one of our online forms, making an application for one of our products or services,
            interacting with us on social media, or corresponding with us. The types of information we obtain in these
            contexts include:
          </CenteredParagraph>
          <ul>
            <li>
              Contact information of the business entity and its personnel who interact with us, such as name, job
              title, address, telephone number, and email address
            </li>
            <li>
              Profile information, such as username and password that an individual may establish on one of our websites
              or mobile applications, along with any other information that an individual enters into their account
              profile
            </li>
            <li>Demographic details, such as date of birth, country of citizenship and/or country of residence</li>
            <li>Information about individuals’ affiliation with a business entity.</li>
            <li>
              Feedback and correspondence, such as information you provide when you request information from us, receive
              customer support, or otherwise correspond with us, including by interacting with our pages on social
              networking online sites or services
            </li>
            <li>Information about merchants, such as merchant name, merchant ID.</li>
            <li>
              Marketing information, such as your preferences for receiving marketing communications, merchant surveys,
              and details about how you engage with our marketing communications
            </li>
          </ul>
          <CenteredParagraph>
            <Strong>
              <i>C. Information that we collect from private and publicly accessible sources</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            We and our service providers may collect information about individuals that is publicly available, including
            by searching publicly accessible media and the internet.
          </CenteredParagraph>
          <CenteredParagraph>
            We may also maintain pages for our company and our products and services on a variety of third-party
            platforms, such as LinkedIn, Facebook, Twitter, Google+, YouTube, Instagram, and other social networking
            services. When individuals interact with our pages on those third-party platforms, the third-party’s privacy
            policy will govern your interactions on the relevant platform. If the third-party platform provides us with
            information about our pages on those platforms or your interactions with them, we will treat that
            information in accordance with this Privacy Notice.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Cookies and Third Party Technologies</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>User Information</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            We may collect user information (“user information”) from your browser or mobile device each time you access
            the Site or use the Services. User information may include anonymous information such as your web request,
            Internet Protocol (“IP”) address, browser type, information about your mobile device, referring/exit pages
            and URLs, domain names, landing pages, pages viewed, and other such information. When you access our
            Services by or through a mobile device, we may receive or collect and store a unique identification number
            associated with your device, mobile carrier, device type and manufacturer, phone number, and, depending on
            your mobile device settings, your geographical location data, including GPS coordinates or similar
            information regarding the location of your mobile device.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>Cookies</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            Cookies are small files comprised of letters and numbers that are downloaded onto your desktop computer,
            mobile or other handheld device when you access certain websites.
          </CenteredParagraph>
          <CenteredParagraph>
            Cookies allow the Site to recognize a user’s device and help your browser navigate through the Site by
            allowing you to log in automatically by remembering settings you selected during earlier visits, among other
            functions. Cookies do not harm your computer.
          </CenteredParagraph>
          <CenteredParagraph>
            We use cookies on the Site to collect statistical information about how visitors use our website, so we can
            improve the way the Site works and measure our success. By recording such statistics as browser usage,
            visitor location, operating system etc. we can measure and improve on how we manage and maintain our site,
            in order to provide a better visitor experience.
          </CenteredParagraph>
          <CenteredParagraph>
            If you would like to manage your use of cookies then you can find out how to do so by visiting{" "}
            <Link href="https://www.allaboutcookies.org/">www.allaboutcookies.org.</Link>
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>Third Party Technologies</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            We do not sell personal information to anyone. We may work with agents, service providers, affiliates, or
            other third party providers which store and receive data, reports, and other user information sent by your
            browser or mobile device, including the pages you visit, your use of our Site, your use of third party
            applications, and other information that we may use to analyze and improve the Site or the Services. These
            third parties may use both temporary and persistent cookies. We do not have control over how third parties
            use your information. Please check their websites for information about how the third parties use your
            information.
          </CenteredParagraph>
          <CenteredParagraph>
            Our Site or Services may contain links to third party websites. We are not responsible for the way third
            party websites collect or use your PII or user information. We encourage you to review the privacy policies
            of third party websites that you visit or use, including those you interact with through our Site or
            Services.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>Information Sharing</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            We may disclose information we collect from you, or that you provide, to the following third parties:
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Service Providers. </Strong> We may share your PII or user information with providers who perform
            services on our behalf.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Business Partners. </Strong> We may share PII or user information about you with business partners
            that provide services and functionality.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Legal Compliance.</Strong> We may share PII or user information about you if we believe in good
            faith that disclosing information is necessary to comply with a valid legal process, governmental request,
            or applicable law; to investigate potential Terms of Service violations; to protect the rights of other
            users and visitors; or to detect and resolve fraud or security concerns.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Accessing and Updating Contact Information</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            Users can easily access, correct or update their contact information at any time by contacting us via email
            at <Link href="mailto:info@csiworks.net">info@csiworks.net.</Link> Alternatively, users can contact us via
            phone at 619-246-8236. International merchants may use whatsap to reach us via same number 619-246-8236.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Unsubscribe from Marketing</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            We may use your PII to contact you with newsletters, marketing or promotional materials and other
            information that may be of interest to you. If you receive marketing e-mail messages from us and wish to opt
            out of receiving such messages, then you may simply follow the opt-out procedure specified in such e-mail.
            All emails sent from the Site have unsubscribe links. If you have problems opting out of any list for any
            reason please contact us at <Link href="mailto:info@csiworks.net">info@csiworks.net.</Link> Alternatively,
            users can contact us via phone at 619-246-8236
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Security</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            We use appropriate administrative, technical, organizational, and physical security measures to protect our
            users' and visitors' information and data against accidental or unlawful destruction, loss, and alteration,
            and against unauthorized disclosure and access. We use standard industry practices to protect PII, visitor
            and user information, including firewalls and SSL encryption.
          </CenteredParagraph>
          <CenteredParagraph>
            Unfortunately, no data transmission or storage can be guaranteed to be 100% secure. While protecting your
            personal information is important to us, we cannot guarantee security of the information you transmit to us
            or host with us using our Site or Services.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Your Choices Regarding Your Information</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Do Not Track</Strong>
            <i></i>
          </CenteredParagraph>
          <CenteredParagraph>
            Certain web browsers may provide an option by which you may have the browser inform websites or internet
            services you visit that you do not wish to have PII or user information about your activities tracked by
            cookies or other persistent identifiers across time and across third party Internet websites, online or
            cloud computing services, online applications, or mobile applications. These are commonly called “do not
            track” signals.
          </CenteredParagraph>
          <CenteredParagraph>
            Because we want to provide you with a personalized experience on the Service, we do not change our behavior
            based on a web browser’s Do Not Track signal.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>Data Retention</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            At our discretion, we will retain all PII and user information that we collect. We retain PII and user
            information we collect from you where we have an ongoing legitimate business need to do so (for example, to
            provide you with the Services you have requested or to comply with applicable legal, tax or accounting
            requirements). Please contact us if you wish us to remove your PII or user information, at{" "}
            <Link href="mailto:info@csiworks.net">info@csiworks.net.</Link>
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>Location-Specific Information</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            If you have any concerns about the way we use your information or any questions about this Privacy Policy,
            please contact us. We can be contacted via email at{" "}
            <Link href="mailto:info@csiworks.net">info@csiworks.net.</Link> Alternatively, users can contact us via
            phone at 619-246-8236
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>California</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            If you are a resident of California, California Civil Code Section 1798.83 permits you to request
            information regarding the disclosure of your PII by us to third parties for the third parties’ direct
            marketing purposes. This applies only to their activities within the State of California. To make such a
            request, please contact us at <Link href="mailto:info@csiworks.net">info@csiworks.net.</Link> Alternatively,
            users can contact us via phone at 619-246-8236
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>Additional information for European users</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>A. Controller and Data Protection POC</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            CSI Works is a member of Sell Seven Inc. CSI Works. is the controller of your personal data for purposes of
            European data protection law. CSI Works has appointed a data protection POC who is responsible for
            overseeing questions in relation to this Privacy Notice. If you have any questions about this Privacy
            Notice, including any requests to exercise your legal rights, please contact the POC using the details set
            out below:
          </CenteredParagraph>
          <CenteredParagraph>
            Attn: Data Protection Officer, CSI Works Email address:{" "}
            <Link href="mailto:info@csiworks.net">info@csiworks.net</Link>
          </CenteredParagraph>
          <CenteredParagraph>Postal address: PO BOX 927435 San Diego, CA 92192, USA</CenteredParagraph>
          <CenteredParagraph>
            <Strong>
              <i>International Data Transfers</i>
            </Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            Visitor and user information may be transferred to, and processed in, countries other than the country in
            which they reside. Specifically, the Site and Services are hosted on servers located in the United States of
            America, and our third party service providers and partners may operate around the world. This means that
            when we collect visitor and user information, such information may be processed in any of these countries.
            If we make such a transfer, we will require that the recipients of your personal data provide data security
            and protection in accordance with applicable requirements of DGPR
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Your Data Protection Rights</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            You have the right to access, correct, update or request deletion of the PII we collect about you. Please
            send such requests to <Link href="mailto:info@csiworks.net">info@csiworks.net.</Link>. You have the right to
            opt out of marketing communications we send you at any time.
          </CenteredParagraph>
          <CenteredParagraph>
            We will respond to all requests from individuals wishing to exercise their data protection rights in
            accordance with applicable data protection laws.
          </CenteredParagraph>
          <CenteredParagraph>
            In addition, if you reside in the European Economic Area or Switzerland, you also have the following rights:
          </CenteredParagraph>
          <CenteredParagraph>
            You have the right to object to processing of your personal information, ask us to restrict processing of
            your personal information or request portability of your personal information. You can exercise these rights
            by contacting us at <Link href="mailto:info@csiworks.net">info@csiworks.net.</Link> Alternatively, users can
            contact us via phone at 619-246-8236
          </CenteredParagraph>
          <CenteredParagraph>
            If we have collected and process your personal information with your consent, then you can withdraw your
            consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted
            prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance
            on lawful processing grounds other than consent.
          </CenteredParagraph>
          <CenteredParagraph>
            You have the right to complain to the data protection authority about our collection and use of your
            personal information. For more information, please contact your local data protection authority. (Contact
            details for data protection authorities in the European Economic Area, Switzerland and certain non-European
            countries (including the US and Canada) are available here:{" "}
            <Link href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm.">
              http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm.
            </Link>
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Children Under the Age of 16</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            The Site is not designed for use by children under the age of 16. We do not intentionally collect PII of any
            person under 16 years of age. If you suspect that someone under 16 years of age has provided PII to us,
            please contact us and we will remove that information from our records.
          </CenteredParagraph>
          <CenteredParagraph>
            <Strong>Notice of Changes</Strong>
          </CenteredParagraph>
          <CenteredParagraph>
            We reserve the right to change this Privacy Policy from time to time as necessary to reflect changing legal,
            regulatory or operational requirements. We will provide notification of the material changes to this Privacy
            Policy through the Website or email at least thirty (30) business days prior to the change taking effect.
          </CenteredParagraph>
          <CenteredParagraph>
            If you have questions about or need further information concerning the legal basis on which we collect and
            use your personal information, please contact us at{" "}
            <Link href="mailto:info@csiworks.net">info@csiworks.net.</Link> Alternatively, users can contact us via
            phone at 619-246-8236
          </CenteredParagraph>
        </MainBlock>
      </Container>
      <BlankFooter />
    </Content>
  );
}

export default PrivacyPolicyPage;
