import * as TYPES from "./YoutubeOauthTypes";

const INITIAL_STATE = {
  isSend: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.YOUTUBE_OAUTH_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case TYPES.YOUTUBE_OAUTH_RECEIVE:
      return {
        ...state,
        channels: action.payload,
        isSend: true,
      };
    default:
      return state;
  }
};

function markActivated(allUsers, userId) {
  const user = allUsers.find((user) => user.id === userId);
  user.activated = true;

  const result = [];
  result.push(...allUsers);
  return result;
}

function markDeactivated(allUsers, userId) {
  const user = allUsers.find((user) => user.id === userId);
  user.activated = false;

  const result = [];
  result.push(...allUsers);
  return result;
}
