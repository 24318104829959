import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  CardActionArea,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

import SoundCloudDefault from "../images/soundcloud-logo.jpg";

export const HugeDescriptionHolder = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 80%);
    pointer-events: none;
  }
`;

function PlaylistCard({ item }) {
  const [showFullDesc, setShowFullDesc] = useState(false);
  const history = useHistory();

  const isDescriptionLong = item.description.length > 120;

  return (
    <Card elevation={3}>
      <CardActionArea
        onClick={() => {
          history.push(`/knowledge-base/channel/${item.channelId}/playlist/${item.id}`);
        }}
      >
        <CardHeader
          title={item.title}
          titleTypographyProps={{ style: { minHeight: "70px" } }}
          subheader={`By ${item.channelTitle}`}
          action={
            <Typography variant="subtitle1" component="p" style={{ paddingTop: "10px", paddingRight: "10px" }}>
              Exclusive
            </Typography>
          }
        />
        <CardContent>
          <Typography variant="h6" component="p" style={{ visibility: item.description ? "visible" : "hidden" }}>
            Description:
          </Typography>
          <Typography variant="body1" component="div" style={{ height: "60px" }}>
            {isDescriptionLong ? <HugeDescriptionHolder>{item.description}</HugeDescriptionHolder> : item.description}
          </Typography>
          <Link
            onClick={(e) => {
              e.stopPropagation();
              setShowFullDesc(true);
            }}
            style={{ visibility: isDescriptionLong ? "visible" : "hidden" }}
          >
            <Typography variant="body1" component="p" style={{ minHeight: "25px", textAlign: "center" }}>
              Show Full Description
            </Typography>
          </Link>
        </CardContent>
        <CardMedia
          component="img"
          alt="Playlist cover"
          height="200"
          image={item.format === "audio" ? SoundCloudDefault : item.youTubeCoverUrl}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="body1">
            Content Format: <b>{item.format === "video" ? "Videos" : "Tracks"}</b>
          </Typography>
          <Typography gutterBottom variant="body1">
            Number of {item.format === "video" ? "videos" : "tracks"}: <b>{item.numberOfItems}</b>
          </Typography>
        </CardContent>
      </CardActionArea>
      <Dialog maxWidth="md" open={showFullDesc} onClose={() => setShowFullDesc(false)}>
        <DialogTitle id="customized-dialog-title" onClose={() => setShowFullDesc(false)}>
          Description
          <IconButton
            aria-label="close"
            style={{ position: "absolute", top: "8px", right: "8px" }}
            onClick={() => setShowFullDesc(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" component="div">
            {item.description}
          </Typography>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

export default PlaylistCard;
