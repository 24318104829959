import { useParams } from "react-router-dom";

import ChannelsSoundcloudPage from "./ChannelsSoundcloudPage";

import ChannelsYoutubePage from "./ChannelsYoutubePage";

function ChannelsPage() {
  const { type } = useParams();
  if (type === "soundcloud") {
    return <ChannelsSoundcloudPage />;
  } else if (type === "youtube") {
    return <ChannelsYoutubePage />;
  }

  return <>Invalid channel type</>;
}

export default ChannelsPage;
