import * as TYPES from "./ChannelByIdTypes";

export const channelByIdRequest = (channelId) => ({
  type: TYPES.CHANNEL_BY_ID_REQUEST,
  channelId,
});

export const channelByIdReceive = (channelId, payload) => ({
  type: TYPES.CHANNEL_BY_ID_RECEIVE,
  channelId,
  payload,
});
