const TOKEN = "token";
const VALID_UNTIL = "valid-until";
const USER = "user";

const Auth = {
  isLoggedIn() {
    const token = localStorage.getItem(TOKEN);
    const validUntil = localStorage.getItem(VALID_UNTIL);

    if (!token || !validUntil) {
      return false;
    }

    // reduce by 30 seconds to prevent token expiration in the middle of page load
    return token && Number(validUntil) > Date.now() - 30000;
  },
  getToken() {
    const token = localStorage.getItem(TOKEN);
    return token;
  },
  getUser() {
    const user = localStorage.getItem(USER);
    return JSON.parse(user);
  },
  setAuth(token, validUntil, user) {
    localStorage.setItem(TOKEN, token);
    localStorage.setItem(VALID_UNTIL, validUntil);
    localStorage.setItem(USER, JSON.stringify(user));
  },
  signOut() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(VALID_UNTIL);
    localStorage.removeItem(USER);
  },
};

export default Auth;
