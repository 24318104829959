import { Container, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react'
import './Brands.css'

const BrandsPage = () => {
    return (
        <>
          <Grid>
            <Typography variant="h4" >
              Buy Equipment In Bulk
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="subtitle1"
                
                gutterBottom
              >
                When you source hardware equipment and accessories from AgentStack
                you earn income and ensure that your merchants get 100% support and
                training on equipment you sell it. Just register the hardware you
                onboard.
              </Typography>
              <TextField
                name="search"
                label="Search"
                style={{ width: "300px", margin: "0px 20px" }}
                size="small"
                variant="outlined"
                onChange={(e) => {}}
              />
            </div>
          <hr />
          </Grid>
          <Typography
              variant="subtitle1"
              style={{ fontWeight: "bold" }}
            >
             Home {" > "} By Scale Brand
            </Typography>
          <Container  maxWidth="lg" >
           
            <Grid container  spacing={1} style={{marginTop: '20px'}} align="center" justify="center" >
              <Grid item >
                <div className="card__brand">
                    <Typography>Buy Scale Brand</Typography>
                </div>
              </Grid>
              <Grid item  >
                <div className="card__brand" >
                <Typography>Buy Scale Price</Typography>
                </div>
              </Grid>
              
            </Grid>
              <Grid  container spacing={1} align="center" justify="center">
              <Grid item >
                <div className="card__brand">
                <Typography>Buy Scale Capacity</Typography>
                </div>
              </Grid>
              <Grid item >
                <div className="card__brand">
                <Typography>Buy Industry's Choice</Typography>
                </div>
              </Grid>
            
              </Grid>
          </Container>
        </>
      );
    };


export default BrandsPage
