import { all } from "redux-saga/effects";

import { ProfileSaga } from "./profile/ProfileSagas";
import { UsersSaga } from "./users/UsersSagas";
import { YoutubeOauthSaga } from "./youtube-oauth/YoutubeOauthSagas";
import { ChannelsSaga } from "./channels/ChannelsSagas";
import { ChannelByIdSaga } from "./channel-by-id/ChannelByIdSagas";
import { PlaylistsSaga } from "./playlists/PlaylistsSagas";
import { PlaylistByIdSaga } from "./playlist-by-id/PlaylistByIdSagas";
import { KnowledgeBaseSaga } from "./knowledge-base/KnowledgeBaseSagas";
import { ExclusiveVideosSaga } from "./exclusive-videos/ExclusiveVideosSagas";

export function* watchSagas() {
  yield all([
    ProfileSaga(),
    UsersSaga(),
    YoutubeOauthSaga(),
    ChannelsSaga(),
    ChannelByIdSaga(),
    PlaylistsSaga(),
    PlaylistByIdSaga(),
    KnowledgeBaseSaga(),
    ExclusiveVideosSaga(),
  ]);
}
