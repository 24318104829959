import { Container, Grid, Step, StepLabel, Stepper, TextField, Typography } from "@material-ui/core";
import React from "react";
import "./Scale.css";

const Scale = () => {
  return (
    <>
      <Grid>
        <Typography variant="h4" >
          Buy Equipment In Bulk
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle1"
            
            gutterBottom
          >
            When you source hardware equipment and accessories from AgentStack
            you earn income and ensure that your merchants get 100% support and
            training on equipment you sell it. Just register the hardware you
            onboard.
          </Typography>
          <TextField
            name="search"
            label="Search"
            style={{ width: "300px", margin: "0px 20px" }}
            size="small"
            variant="outlined"
            onChange={(e) => {}}
          />
        </div>
      <hr />
      </Grid>
      <Typography
          variant="h6"
          style={{ fontWeight: "normal" }}
        >
          Buy New Scale For Clover in Bulk
        </Typography>
      <Container  maxWidth="lg" >
       
        <Grid container spacing={1} align="center" justify="center" >
          <Grid item >
            <div className="card">
                <Typography>By Scale Brand</Typography>
            </div>
          </Grid>
          <Grid item  >
            <div className="card" >
            <Typography>By Scale Price</Typography>
            </div>
          </Grid>
          <Grid item >
            <div className="card">
            <Typography>By Scale Attributes</Typography>
            </div>
          </Grid>
        </Grid>
          <Grid  container spacing={1} align="center" justify="center">
          <Grid item >
            <div className="card">
            <Typography>By Scale Capacity</Typography>
            </div>
          </Grid>
          <Grid item >
            <div className="card">
            <Typography>By Industry's Choice</Typography>
            </div>
          </Grid>
          <Grid item >
            <div className="card">
                Expert Consultation
            </div>
          </Grid>
          </Grid>
      </Container>
    </>
  );
};

export default Scale;
