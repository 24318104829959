import * as TYPES from "./PlaylistByIdTypes";

const INITIAL_STATE = {
  isLoaded: false,
  isUpdating: false,
  isDeleting: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.PLAYLIST_BY_ID_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isUpdating: false,
        isDeleting: false,
      };
    case TYPES.PLAYLIST_BY_ID_RECEIVE:
      return {
        ...state,
        playlist: action.payload,
        loadedPlaylistId: action.playlistId,
        isLoaded: true,
      };
    case TYPES.PLAYLIST_ITEM_ADD:
      return {
        ...state,
        isUpdating: true,
      };
    case TYPES.PLAYLIST_ITEM_ADD_COMPLETED:
      state.playlist.items.push(action.payload);
      return {
        ...state,
        isUpdating: false,
      };
    case TYPES.PLAYLIST_ITEM_DELETE:
      return {
        ...state,
        isDeleting: true,
      };
    case TYPES.PLAYLIST_ITEM_DELETE_COMPLETED:
      state.playlist.items = state.playlist.items.filter((plItem) => plItem.id !== action.playlistItemId);
      return {
        ...state,
        isDeleting: false,
      };
    default:
      return state;
  }
};
