import { axiosInstance } from "../../network/api";
const handlerEnabled = true;

const apiFetchKnowledgeBase = async () => {
  return await axiosInstance.get(`/playlists`, { handlerEnabled });
};

export default {
  apiFetchKnowledgeBase,
};
