import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  CssBaseline,
  Drawer,
  Divider,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import YouTubeIcon from "@material-ui/icons/YouTube";
import HearingIcon from "@material-ui/icons/Hearing";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AssignmentIcon from '@material-ui/icons/Assignment';
import UserInfo, { UserInfoMobile } from "../UserInfo";
import EditIcon from '@material-ui/icons/Edit';
import {
  HeaderContainer,
  BurgerMenuHolder,
  LogoHolder,
  PageContentContainer,
  NavContainer,
  MainContentContainer,
  PrivacyTermsHolder,
  PrivacyTermsLinkWrapper,
} from "./style";
import Logo from "./Logo";
import Auth from "../../Auth";

import { allChannelsRequest } from "../../store/channels/ChannelsAction";
import { AssignmentIndOutlined, Shop, ShopOutlined, ShoppingBasketTwoTone } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
    "margin-top": "72px",
  },
  drawerPaperMobile: {
    width: drawerWidth,
    height: "100%",
  },
}));

function Header({ children, logoLink }) {
  const classes = useStyles();

  const user = Auth.getUser();

  const channels = useSelector((state) => state.channels);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.role === "admin") {
      dispatch(allChannelsRequest());
    }
  }, [dispatch]);

  let menuContent = null;

  if (user.role === "admin") {
    let channelsMenu = null;
    if (channels.isLoaded) {
      const youtubeChannels = channels.allChannels.filter((ch) => ch.type === "youtube");
      const soundcloudChannels = channels.allChannels.filter((ch) => ch.type === "soundcloud");

      channelsMenu = (
        <>
          <RouterLink to="/admin/channels/youtube" className="plainLink">
            <ListItem button key={4}>
              <ListItemIcon>
                <YouTubeIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="YouTube" />
            </ListItem>
          </RouterLink>
          <List component="div" disablePadding>
            {youtubeChannels.map((channel, index) => (
              <RouterLink to={`/admin/channels/youtube/${channel.id}`} key={index} className="plainLink">
                <ListItem button style={{ paddingLeft: "50px" }}>
                  <ListItemText primary={channel.title} primaryTypographyProps={{ variant: "body2" }} />
                </ListItem>
              </RouterLink>
            ))}
          </List>
          <Divider style={{ marginTop: "20px" }} />
          <RouterLink to="/admin/channels/soundcloud" className="plainLink">
            <ListItem button key={4}>
              <ListItemIcon>
                <HearingIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="SoundCloud" />
            </ListItem>
          </RouterLink>
          <List component="div" disablePadding>
            {soundcloudChannels.map((channel, index) => (
              <RouterLink to={`/admin/channels/soundcloud/${channel.id}`} key={index} className="plainLink">
                <ListItem button style={{ paddingLeft: "50px" }}>
                  <ListItemText primary={channel.title} primaryTypographyProps={{ variant: "body2" }} />
                </ListItem>
              </RouterLink>
            ))}
          </List>
        </>
      );
    } else {
      channelsMenu = (
        <ListItem key={999} style={{ justifyContent: "center" }}>
          <CircularProgress />
        </ListItem>
      );
    }
    menuContent = (
      <>
        <RouterLink to="/admin" className="plainLink">
          <ListItem button key={1}>
            <ListItemIcon>
              <HomeIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
        </RouterLink>
        <Divider />
        <RouterLink to="/admin/users" className="plainLink">
          <ListItem button key={2}>
            <ListItemIcon>
              <PeopleAltIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </RouterLink>
        <Divider />
        <RouterLink to="/admin/applications" className="plainLink">
          <ListItem button key={2}>
            <ListItemIcon>
              <AssignmentIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="Wholesaler Applications" />
          </ListItem>
        </RouterLink>
        <Divider />
        <RouterLink to="/knowledge-base" className="plainLink">
          <ListItem button key={3}>
            <ListItemIcon>
              <FolderSharedIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="Knowledge base" />
          </ListItem>
        </RouterLink>
        <Divider />
        {channelsMenu}
      </>
    );
  } else {
    menuContent = (
      <>
        <RouterLink to="/home" className="plainLink">
          <ListItem button key={1}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </RouterLink>
        <RouterLink to="/knowledge-base" className="plainLink">
          <ListItem button key={2}>
            <ListItemIcon>
              <FolderSharedIcon />
            </ListItemIcon>
            <ListItemText primary="Knowledge base" />
          </ListItem>
        </RouterLink>
        <RouterLink to="/buy" className="plainLink">
          <ListItem button key={2}>
            <ListItemIcon>
              <Shop />
            </ListItemIcon>
            <ListItemText primary="Buy Equipment in Bulk" />
          </ListItem>
        </RouterLink>
      </>
    );
  }

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerContent = (
    <List>
      {menuContent}
      <PrivacyTermsHolder>
        <Divider />
        <PrivacyTermsLinkWrapper>
          <a href="https://csiworks.net/privacy-policy/" target="_blank">
            <Typography>CSI Works Privacy Terms</Typography>
          </a>
        </PrivacyTermsLinkWrapper>
      </PrivacyTermsHolder>
    </List>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <CssBaseline />
      <HeaderContainer>
        <BurgerMenuHolder>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
            <MenuIcon fontSize="large" />
          </IconButton>
        </BurgerMenuHolder>
        <LogoHolder>
          <RouterLink to={user.role === "admin" ? "/admin" : "/home"} className="plainLink">
            <Logo />
          </RouterLink>
        </LogoHolder>
        <UserInfo />
      </HeaderContainer>
      <PageContentContainer>
        <NavContainer className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaperMobile,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <div>
                <UserInfoMobile />
                <Divider />
                <span onClick={handleDrawerToggle}>{drawerContent}</span>
              </div>
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
                docked: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawerContent}
            </Drawer>
          </Hidden>
        </NavContainer>
        <MainContentContainer>{children}</MainContentContainer>
      </PageContentContainer>
    </>
  );
}

export default Header;
