import { call, put, takeLatest } from "redux-saga/effects";

import API from "./PlaylistsApis";
import * as ACTIONS from "./PlaylistsAction";
import * as TYPES from "./PlaylistsTypes";

export function* fetchAllPlaylistsForChannel({ channelId }) {
  try {
    const response = yield call(API.apiFetchAllPlaylistsForChannel, channelId);
    yield put(ACTIONS.allPlaylistsForChannelReceive(channelId, response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* enablePlaylist({ playlistId, value }) {
  try {
    const method = value ? API.apiEnablePlaylist : API.apiDisablePlaylist;
    yield call(method, playlistId, value);
    yield put(ACTIONS.playlistEnableReceive(playlistId, value));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* createPlaylist({ payload }) {
  try {
    const response = yield call(API.apiCreatePlaylist, payload);
    yield put(ACTIONS.createPlaylistCompleted(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* updatePlaylist({ playlistId, payload }) {
  try {
    const response = yield call(API.apiUpdatePlaylist, playlistId, payload);
    yield put(ACTIONS.updatePlaylistCompleted(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* deletePlaylist({ playlistId }) {
  try {
    yield call(API.apiDeletePlaylist, playlistId);
    yield put(ACTIONS.deletePlaylistCompleted(playlistId));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* PlaylistsSaga() {
  yield takeLatest(TYPES.PLAYLISTS_FOR_CHANNEL_REQUEST, fetchAllPlaylistsForChannel);
  yield takeLatest(TYPES.PLAYLIST_ENABLE, enablePlaylist);
  yield takeLatest(TYPES.PLAYLIST_CREATE, createPlaylist);
  yield takeLatest(TYPES.PLAYLIST_UPDATE, updatePlaylist);
  yield takeLatest(TYPES.PLAYLIST_DELETE, deletePlaylist);
}
