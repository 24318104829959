import { axiosInstance } from "../../network/api";
const handlerEnabled = true;

const apiFetchProfile = async (profileId) => {
  return await axiosInstance.get(`/profiles/${profileId}`, { handlerEnabled });
};

const apiUpdateProfile = async (profileId, payload) => {
  return await axiosInstance.put(`/profiles/${profileId}`, payload, { handlerEnabled });
};

const apiChangePassword = async (profileId, payload) => {
  return await axiosInstance.put(`/profiles/${profileId}/change-password`, payload, { handlerEnabled });
};

export default {
  apiFetchProfile,
  apiUpdateProfile,
  apiChangePassword,
};
