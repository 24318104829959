import { call, put } from "redux-saga/effects";
import API from "./ProfileApis";
import * as ACTIONS from "./ProfileAction";
import { takeLatest, takeLeading } from "redux-saga/effects";
import * as TYPES from "./ProfileTypes";

export function* fetchProfileData({ profileId }) {
  try {
    const response = yield call(API.apiFetchProfile, profileId);
    yield put(ACTIONS.profileReceive(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* updateProfileData({ profileId, payload }) {
  try {
    const response = yield call(API.apiUpdateProfile, profileId, payload);
    yield put(ACTIONS.profileReceive(response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* changePassword({ profileId, payload }) {
  try {
    const response = yield call(API.apiChangePassword, profileId, payload);
    yield put(ACTIONS.profileChangedPassword());
  } catch (err) {
    yield put(ACTIONS.profileChangePasswordError(err.response.data));
  }
}

export function* ProfileSaga() {
  yield takeLeading(TYPES.PROFILE_REQUEST, fetchProfileData);
  yield takeLatest(TYPES.PROFILE_UPDATE, updateProfileData);
  yield takeLatest(TYPES.PROFILE_CHANGING_PASSWORD, changePassword);
}
