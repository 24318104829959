import { call, put, takeLatest } from "redux-saga/effects";

import API from "./PlaylistByIdApis";
import * as ACTIONS from "./PlaylistByIdAction";
import * as TYPES from "./PlaylistByIdTypes";

export function* fetchPlaylistById({ channelId, playlistId }) {
  try {
    const response = yield call(API.apiFetchPlaylistById, channelId, playlistId);
    yield put(ACTIONS.playlistByIdReceive(playlistId, response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* createPlaylistItem({ playlistId, payload }) {
  try {
    const response = yield call(API.apiAddItemToPlaylist, playlistId, payload);
    yield put(ACTIONS.addItemToPlaylistCompleted(playlistId, response.data));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* deletePlaylistItem({ playlistId, playlistItemId }) {
  try {
    yield call(API.apiDeleteItemFromPlaylist, playlistId, playlistItemId);
    yield put(ACTIONS.deleteItemFromPlaylistCompleted(playlistId, playlistItemId));
  } catch (err) {
    alert(`API call error: ${err.response.data}`);
  }
}

export function* PlaylistByIdSaga() {
  yield takeLatest(TYPES.PLAYLIST_BY_ID_REQUEST, fetchPlaylistById);
  yield takeLatest(TYPES.PLAYLIST_ITEM_ADD, createPlaylistItem);
  yield takeLatest(TYPES.PLAYLIST_ITEM_DELETE, deletePlaylistItem);
}
