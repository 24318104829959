export const PLAYLISTS_FOR_CHANNEL_REQUEST = "PLAYLISTS_FOR_CHANNEL_REQUEST";
export const PLAYLISTS_FOR_CHANNEL_RECEIVE = "PLAYLISTS_FOR_CHANNEL_RECEIVE";

export const PLAYLIST_CREATE = "PLAYLIST_CREATE";
export const PLAYLIST_CREATE_COMPLETED = "PLAYLIST_CREATE_COMPLETED";

export const PLAYLIST_UPDATE = "PLAYLIST_UPDATE";
export const PLAYLIST_UPDATE_COMPLETED = "PLAYLIST_UPDATE_COMPLETED";

export const PLAYLIST_DELETE = "PLAYLIST_DELETE";
export const PLAYLIST_DELETE_COMPLETED = "PLAYLIST_DELETE_COMPLETED";

export const PLAYLIST_ENABLE = "PLAYLIST_ENABLE";
export const PLAYLIST_ENABLE_COMPLETED = "PLAYLIST_ENABLE_COMPLETED";

export const PLAYLIST_DISABLE = "PLAYLIST_DISABLE";
export const PLAYLIST_DISABLE_COMPLETED = "PLAYLIST_DISABLE_COMPLETED";
