import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  Box,
  Typography,
  LinearProgress,
  Link,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import "./ProfilePage.css";
import { useDropzone } from "react-dropzone";
import Auth from "../../Auth";
import {
  profileRequest,
  profileUpdate,
} from "../../store/profile/ProfileAction";

import PaperContentHolder from "../../components/PaperContent";
import { useState } from "react";
import DropFileInput from "../../components/dnd/DropFileInput";
import axios from "axios";
import { BASE_URL } from "../../config";
import { axiosInstance } from "../../network/api";
import OtpInput from "react-otp-input";

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().min(14, "Incomplete phone number").required("Required"),
});

function ProfilePage() {
  const handlerEnabled = true;
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [uploadForm, setUploadForm] = useState(false);
  const [reviewMessage, setReviewMessage] = useState(false);
  const [code, setCode] = useState("")
  const [qrcode, setQrcode] = useState();
  const { user } = Auth.getUser();
  useEffect(() => {
    dispatch(profileRequest(Auth.getUser().id));
  }, [dispatch]);

  useEffect(() => {
    if (profile.role === "admin") {
      fetchQRCodeForAdmin();
    }
  }, []);

  if (!profile.isLoaded) {
    return (
      <Paper square={true} variant="outlined" style={{ height: "100%" }}>
        <LinearProgress />
      </Paper>
    );
  }

  const fetchQRCodeForAdmin = async () => {
    const image = await axios.get(`${BASE_URL}/barcode/${profile.email}`, {
      responseType: "arraybuffer",
    });
    let base64ImageString = Buffer.from(image.data, "binary").toString(
      "base64"
    );
    setQrcode(base64ImageString);
  };
  console.log(profile)

  const handleUpload = async (files) => {
    setUploadForm(false);
    let element = document.getElementById("uploadButton");
    element.textContent = " On Review. Please wait for our response.";
    element.style.border = "none";
    element.style.color = "#30b0df";
    var formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("userId", profile?.id);
    await axiosInstance.post(`/dox/upload`, formData, {
      "Content-Type": "multipart/form-data",
    });
  };
  function handleForm(e) {
    e.preventDefault();
    setUploadForm(true);
  }

 async function reGenerateCode(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    const element = document.getElementById("code")
    element.textContent = result
   await axiosInstance.put(`/profiles?userId=${profile.id}&code=${result}`,{handlerEnabled})
  }

  return (
    <Paper
      square={true}
      variant="outlined"
      style={{ height: "100%", display: "flex", justifyContent: "flex-start" }}
    >
      <LinearProgress
        style={{ visibility: profile.isUpdating ? "visible" : "hidden" }}
      />
      <PaperContentHolder>
        <Formik
          initialValues={{
            firstName: profile.firstName,
            lastName: profile.lastName,
            phone: profile.phone,
            email: profile.email,
            companyName: profile.companyName,
            companyUrl: profile.companyUrl,
            phoneType: profile.phoneType || "",
          }}
          validationSchema={ProfileSchema}
          onSubmit={(values) => {
            dispatch(profileUpdate(profile.id, values));
          }}
        >
          {({
            setFieldValue,
            setFieldTouched,
            submitForm,
            values,
            errors,
            touched,
          }) => (
            <Container style={{ marginTop: "10px" }} maxWidth="md">
              <Typography variant="h4">Edit Profile</Typography>

              <Typography
                variant="h6"
                style={{ marginTop: "30px", color: "#30b0df" }}
              >
                My Information
              </Typography>
              <Form>
                <Grid container justifyContent="flex-start" spacing={2}>
                  <Grid item xs={12} style={{ textAlign: "center" }}></Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="firstName"
                      label="First name"
                      placeholder="John"
                      variant="outlined"
                      disabled={profile.isUpdating}
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="lastName"
                      label="Last name"
                      placeholder="Smith"
                      variant="outlined"
                      disabled={profile.isUpdating}
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>
                  <div>
                    {uploadForm === true && user?.role !== "admin" ? (
                      <Grid item xs={12} sm={6}>
                        <DropFileInput handleUpload={handleUpload} />
                      </Grid>
                    ) : null}
                  </div>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label="Email"
                      placeholder="example@email.com"
                      variant="outlined"
                      disabled={profile.isUpdating}
                      required
                      fullWidth
                      style={{ minHeight: "5rem" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MuiPhoneNumber
                      id="phone"
                      name="phone"
                      label="Phone number"
                      variant="outlined"
                      disableCountryCode={true}
                      disableDropdown={true}
                      onlyCountries={["us", "ca"]}
                      defaultCountry="us"
                      required
                      style={{ minHeight: "5rem" }}
                      onChange={(e) => {
                        setFieldTouched("phone");
                        setFieldValue("phone", e);
                      }}
                      onBlur={(e) => {
                        setFieldTouched("phone");
                      }}
                      value={values.phone}
                      disabled={profile.isUpdating}
                      error={touched.phone && Boolean(errors.phone)}
                      helperText={touched.phone && errors.phone}
                    />
                    <FormControl
                      style={{ minWidth: "200px", marginLeft: "20px" }}
                       
                    >
                      <InputLabel id="select-label">
                        Select Phone Type
                      </InputLabel>
                      <Select
                        name="phoneType"
                        value={values.phoneType}
                        onChange={(e) => {
                          setFieldTouched("phoneType");
                          setFieldValue("phoneType", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldTouched("phoneType");
                        }}
                        disabled={profile.isUpdating}
                        labelId="select-label"
                        label="Label"
                        defaultValue={profile.phoneType}
                      >
                        <MenuItem value="Land Line">Land Line</MenuItem>
                        <MenuItem value="Mobile">Mobile</MenuItem>
                        <MenuItem value="VOIP">VOIP</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {profile.status === "Approved" && profile.role !== "admin" && (
                    <Grid item xs={12} sm={6}>
                      <Typography
                        gutterBottom
                        style={{ color: "#30b0df" }}
                        variant="h6"
                      >
                        Your wholesaler code:
                      </Typography>

                      <Typography
                       variant="h5" 
                       id="code"
                      >{profile.code}</Typography>
                      <Button
                        style={{ marginTop: "10px" }}
                        onClick={(e) => reGenerateCode(8)}
                        variant="outlined"
                        color="primary"
                      >
                        Regenerate
                      </Button>
                    </Grid>
                  )}
                  <Grid container direction="column">
                    <Typography
                      variant="h6"
                      style={{ marginTop: "30px", color: "#30b0df" }}
                    >
                      Company Information
                    </Typography>
                    <Grid
                      style={{ marginTop: "15px" }}
                      container
                      spacing={2}
                      className="companyInfo"
                    >
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="companyName"
                          label="Company Name"
                          placeholder="Company Name"
                          variant="outlined"
                          disabled={profile.isUpdating}
                          fullWidth
                          style={{ minHeight: "5rem" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="companyUrl"
                          label="URL"
                          placeholder="URL"
                          variant="outlined"
                          disabled={profile.isUpdating}
                          fullWidth
                          style={{ minHeight: "5rem" }}
                        />
                      </Grid>
                    </Grid>
                    {profile?.role !== "admin" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ marginRight: "30px" }}
                        >
                          Reseller Permit Provided:{" "}
                          {profile.status === null || profile.status === "" ? (
                              <button
                                id="uploadButton"
                                style={{
                                  color: "#30b0df",
                                  padding: 0,
                                  backgroundColor: "white",
                                  fontSize: "20px",
                                  textDecoration: "underline",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleForm(e)}
                              >
                                No
                              </button>
                            ): null}
                          {profile.status === "onReview" && (
                            <span
                              style={{
                                color: "#30b0df",
                                fontSize: "20px",
                                textDecoration: "underline",
                              }}
                            >
                              On Review. Please wait for our response.
                            </span>
                          )}
                          {profile.status === "Rejected" && (
                            <button
                              id="uploadButton"
                              style={{
                                color: "red",
                                fontSize: "20px",
                                textDecoration: "underline",
                                border: "none",
                                cursor: "pointer",
                                backgroundColor: "#ffffff",
                              }}
                              onClick={(e) => handleForm(e)}
                            >
                              Your documents have been rejected. Please try
                              again.
                            </button>
                          )}
                          {profile.status === "Approved" && (
                            <span
                              style={{
                                color: "#30b0df",
                                fontSize: "20px",
                                textDecoration: "underline",
                              }}
                            >
                              Yes
                            </span>
                          )}
                        </Typography>
                      </div>
                    )}
                  </Grid>
                  {profile?.role === "admin" && (
                    <Grid
                      container
                      direction="column"
                      style={{ textAlign: "center" }}
                    >
                      <Typography variant="h6">
                        QRCode for Google Authenticator
                      </Typography>
                      <img
                        style={{
                          alignSelf: "center",
                          height: "150px",
                          width: "150px",
                        }}
                        src={`data:image/png;base64,${qrcode}`}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={profile.isUpdating}
                      onClick={submitForm}
                      style={{ width: "195px" }}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
                    <Link component={RouterLink} to="/change-password">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={profile.isUpdating}
                        style={{ width: "195px" }}
                      >
                        Change Password
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          )}
        </Formik>
      </PaperContentHolder>
    </Paper>
  );
}

export default ProfilePage;
