import { Typography } from "@material-ui/core";
import styled from "styled-components";

const LogoText = styled.div`
  font-size: 35px;
  font-weight: bold;
  text-align: right;

  @media (max-width: 550px) {
    font-size: 30px;
  }
`;

const LogoTextBy = styled.div`
  margin-top: -10px;
  font-size: 15px;
  font-weight: bold;
  text-align: right;

  @media (max-width: 550px) {
    font-size: 15px;
  }
`;

const LogoTextBlue = styled.span`
  color: #0093c4;
`;

export default function Logo() {
  return (
    <div>
      <Typography component="div">
        <LogoText>
          <LogoTextBlue>A</LogoTextBlue>gentS<LogoTextBlue>tack</LogoTextBlue>.net
        </LogoText>
        <LogoTextBy>by CSI Works</LogoTextBy>
      </Typography>
    </div>
  );
}
