import axios from "axios";
import { BASE_URL } from "../config";
import { axiosInstance } from "./api";
const handlerEnabled = false;

export const apiLogin = async ({ username, password, otp }) => {
  const response = await axiosInstance.post(`/login`, { username,
   password, otp}, { handlerEnabled });

  return response.data;
};

export const apiResetPassword = async ({ email }) => {
  const response = await axiosInstance.post(`/login/reset`, { email }, { handlerEnabled });

  return response.data;
};

export const apiRegisterNewAgent = async (formData) => {
  const response = await axiosInstance.post(`/register`, formData, { handlerEnabled });

  return response.data;
};