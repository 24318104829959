import { axiosInstance } from "../../network/api";
const handlerEnabled = true;

const apiFetchAllChannels = async () => {
  return await axiosInstance.get(`/channels`, { handlerEnabled });
};

const apiCreateChannel = async (payload) => {
  return await axiosInstance.put(`/channels/soundcloud`, payload, { handlerEnabled });
};

const apiUpdateChannel = async (channelId, payload) => {
  return await axiosInstance.post(`/channels/${channelId}`, payload, { handlerEnabled });
};

const apiDeleteChannel = async (channelId) => {
  return await axiosInstance.delete(`/channels/${channelId}`, { handlerEnabled });
};

export default {
  apiFetchAllChannels,
  apiCreateChannel,
  apiUpdateChannel,
  apiDeleteChannel,
};
