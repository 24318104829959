import * as TYPES from "./ChannelsTypes";

export const allChannelsRequest = () => ({
  type: TYPES.CHANNELS_REQUEST,
});

export const allChannelsReceive = (payload) => ({
  type: TYPES.CHANNELS_RECEIVE,
  payload,
});

export const createChannel = (payload) => ({
  type: TYPES.CHANNEL_CREATE,
  payload,
});

export const createChannelCompleted = (payload) => ({
  type: TYPES.CHANNEL_CREATE_COMPLETED,
  payload,
});

export const updateChannel = (channelId, payload) => ({
  type: TYPES.CHANNEL_UPDATE,
  channelId,
  payload,
});

export const updateChannelCompleted = (payload) => ({
  type: TYPES.CHANNEL_UPDATE_COMPLETED,
  payload,
});

export const deleteChannel = (channelId) => ({
  type: TYPES.CHANNEL_DELETE,
  channelId,
});

export const deleteChannelCompleted = (channelId) => ({
  type: TYPES.CHANNEL_DELETE_COMPLETED,
  channelId,
});
